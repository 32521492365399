import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { BeaconIcon, ClusterIcon, DriverIcon, TimeIcon } from '@karnott/icons';
import { Tag } from '@karnott/tags';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const DriverTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${fontFamily()};
`;

const DriverTooltipRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 13px;
`;

const Content = styled.div`
  margin-top: 5px;
  margin-left: ${pixelSpacing('small')};
`;

const DriverTooltipRowHeaderLabel = styled.div`
  margin-left: ${pixelSpacing('small')};
`;

const DriverTooltipRowHeaderLabelName = styled.div`
  font-weight: 700;
  font-size: ${pixelSize('large')};
`;

const DriverTooltipRowIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const DriverTooltipRowLabel = styled.div`
  display: flex;
  margin: 0px ${pixelSpacing('xSmall')};
  text-overflow: hidden;
`;

const DriverTooltipRowAction = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 10px;
  height: 24px;
`;

type Props = {
  /** Name of the driver */
  name: string;
  /** Whether the driver is actively working */
  isActive?: boolean;
  /** Driver beacon UUID */
  beaconUUID?: string;
  /** Name of the driver’s cluster */
  clusterName: string;
  /** Date of the last update */
  lastDate: string;
  /** I18n labels */
  labels: {
    active: string;
    inactive: string;
    last_update: string;
    see: string;
  };
  /** Action to be executed when the chip is clicked */
  chipAction: MouseEventHandler<HTMLButtonElement>;
};

export function DriverPopup({ name, isActive = false, beaconUUID, clusterName, lastDate, labels, chipAction }: Props) {
  return (
    <DriverTooltipWrapper>
      <DriverTooltipRow>
        <DriverIcon circled backgroundColor={colors('black', 'dark')} color={colors('white')} size={40} />
        <DriverTooltipRowHeaderLabel>
          <DriverTooltipRowHeaderLabelName>{name}</DriverTooltipRowHeaderLabelName>
          <Tag
            active={isActive}
            label={isActive ? labels.active.toLocaleUpperCase() : labels.inactive.toLocaleUpperCase()}
          />
        </DriverTooltipRowHeaderLabel>
      </DriverTooltipRow>
      <Content>
        <DriverTooltipRow>
          <DriverTooltipRowIcon>
            <ClusterIcon size={12} />
          </DriverTooltipRowIcon>
          <DriverTooltipRowLabel>{clusterName}</DriverTooltipRowLabel>
        </DriverTooltipRow>

        <DriverTooltipRow>
          <DriverTooltipRowIcon>
            <TimeIcon size={12} />
          </DriverTooltipRowIcon>
          <DriverTooltipRowLabel>{`${labels.last_update} : ${lastDate}`}</DriverTooltipRowLabel>
        </DriverTooltipRow>

        <DriverTooltipRow>
          <DriverTooltipRowIcon>
            <BeaconIcon size={12} />
          </DriverTooltipRowIcon>
          <DriverTooltipRowLabel>{beaconUUID || '-'}</DriverTooltipRowLabel>
        </DriverTooltipRow>
      </Content>

      <DriverTooltipRowAction>
        <Button primary thin title={labels.see} onClick={chipAction} rounded />
      </DriverTooltipRowAction>
    </DriverTooltipWrapper>
  );
}
