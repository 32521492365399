import { createSelector } from '@reduxjs/toolkit';
import { DriverIcon } from '@karnott/icons';
import { DEVICE_CONSTANTS } from '../constants/deviceConstants';
import { clusterContains } from '../utils';
import { beaconsContains } from '../utils/devices';
import { getEquipmentIcon } from '../utils/equipments';
import { getUserName } from '../utils/user';
import { getActiveClusters, getBeacons, getClusters, getEquipments, getSearchFilter, getUsers } from './index';

export const getVisibleBeacons = createSelector(
  [getActiveClusters, getSearchFilter, getBeacons, getClusters],
  (activeClusters, filter, beacons, clusters) =>
    beacons.beaconsIDs
      .map((id) => beacons.beaconsById[id])
      .filter((b) => b.status !== 'DELETED')
      .filter((b) => !activeClusters?.length || activeClusters.includes(b.clusterId))
      .map((beacon) => {
        const cluster = clusters.clusterById[beacon.clusterId];
        return {
          ...beacon,
          cluster: cluster,
          deviceType:
            beacon.type === DEVICE_CONSTANTS.BEACON_TYPE.EQUIPMENT
              ? DEVICE_CONSTANTS.TYPE.BEACON
              : DEVICE_CONSTANTS.TYPE.DRIVER_BEACON,
          hidden: !beaconsContains(beacon, filter) && !clusterContains(cluster, filter),
        };
      }),
);

export const getVisibleEquipmentBeacons = createSelector([getVisibleBeacons], (beacons) =>
  beacons.filter((b) => b.type === DEVICE_CONSTANTS.BEACON_TYPE.EQUIPMENT),
);

export const getDriversBeacon = createSelector([getVisibleBeacons], (beacons) =>
  beacons.filter((b) => b.type === DEVICE_CONSTANTS.BEACON_TYPE.DRIVER),
);

export const getDriversBeaconByClusterId = createSelector([getDriversBeacon], (beacons) =>
  beacons.reduce((beaconsByClusterId, b) => {
    beaconsByClusterId[b.clusterId] = beaconsByClusterId[b.clusterId] || [];
    beaconsByClusterId[b.clusterId].push(b);
    return beaconsByClusterId;
  }, {}),
);

export const getBeaconEquipmentHistory = createSelector([getBeacons, getEquipments], (beacons, equipments) => {
  const { equipmentById, equipmentModelById } = equipments;
  const beaconEquipmentHistoryByID = { ...beacons.beaconEquipmentHistoryByID };

  const updatedBeaconEquipmentHistoryByID = Object.keys(beaconEquipmentHistoryByID).reduce((acc, beaconID) => {
    const history = beaconEquipmentHistoryByID[beaconID];
    const updatedHistory = (history || []).map((historyItem) => {
      const equipment = equipmentById[historyItem.equipment_instance_id];
      const equipmentModel = equipmentModelById[equipment?.equipmentModelId];
      return {
        ...historyItem,
        label: equipment?.label || '',
        icon: getEquipmentIcon((equipmentModel && equipmentModel.type) || 'misc'),
      };
    });
    acc[beaconID] = updatedHistory;
    return acc;
  }, {});

  return updatedBeaconEquipmentHistoryByID;
});

export const getBeaconDriversHistory = createSelector([getBeacons, getUsers], (beacons, drivers) => {
  const beaconDriverHistoryByID = { ...beacons.beaconDriverHistoryByID };

  const updatedBeaconDriverHistoryByID = Object.keys(beaconDriverHistoryByID).reduce((acc, beaconID) => {
    const history = beaconDriverHistoryByID[beaconID];
    const updatedHistory = (history || []).map((historyItem) => {
      const driver = drivers[historyItem.user_id];
      return {
        ...historyItem,
        label: driver ? getUserName(driver) : historyItem.label,
        icon: DriverIcon,
      };
    });
    acc[beaconID] = updatedHistory;
    return acc;
  }, {});

  return updatedBeaconDriverHistoryByID;
});

export const hasEquipmentBeacon = createSelector([getVisibleEquipmentBeacons], (beacons) => beacons.length !== 0);

export const hasDriverBeacon = createSelector([getDriversBeacon], (beacons) => beacons.length !== 0);
