import { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { KIcon, MarkerFillIcon, TimeIcon } from '@karnott/icons';
import { Tag } from '@karnott/tags';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const WorksiteTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${fontFamily()};
`;

const IconsContainer = styled.div<{
  withPadding?: boolean;
}>`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  padding: ${({ withPadding }) => (withPadding ? '0px 8px 0px 10px' : '0px')};
  min-width: 40px;
  min-height: 40px;
  align-items: center;
  position: relative;
`;

const WorksiteTooltipRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  margin-bottom: 5px;
`;

const WorksiteTooltipRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${pixelSpacing('small')};
`;

const WorksiteTooltipRowHeaderLogo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  justify-content: space-between;
`;

const WorksiteTooltipRowHeaderLabel = styled.div``;
const WorksiteTooltipRowHeaderLabelName = styled.div`
  font-weight: 700;
  font-size: ${pixelSize('large')};
`;
const WorksiteTooltipRowIcon = styled.div`
  display: flex;
  justify-content: center;
`;

const WorksiteTooltipRowLabel = styled.div`
  display: flex;
`;

const WorksiteTooltipRowAction = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  & > * {
    margin-top: 5px;
    margin-right: 5px;
    height: 24px;
  }
`;

const IconContainer = styled.div<{
  index?: number;
}>`
  border: 2px solid ${colors('white')};
  border-radius: 30px;
  margin-left: -15px;
  z-index: ${({ index }) => index || 0};
`;

type Props = {
  /** Array of equipment icons to be displayed */
  Icons: KIcon[];
  /** Type of the worksite */
  worksiteType: string;
  /** Date from which the worksite is active */
  fromDate: string;
  /** Date until which the worksite is active */
  toDate?: string;
  /** Flag to indicate if the worksite is in progress */
  isInProgress?: boolean;
  /** Date of the last update */
  lastUpdate?: string;
  /** Array of actions to be displayed */
  actions: { name: string; actionCB: MouseEventHandler<HTMLButtonElement> }[];
  /** I18n labels */
  labels: {
    in_progress: string;
    from_date: string;
    to_date: string;
    last_update: string;
  };
};

export function WorksitePopup({
  Icons,
  worksiteType,
  fromDate,
  isInProgress = false,
  lastUpdate,
  toDate,
  actions,
  labels,
}: Props) {
  const reverseIcons = Icons.toReversed();
  return (
    <WorksiteTooltipWrapper>
      <WorksiteTooltipRowHeader>
        <WorksiteTooltipRowHeaderLogo>
          <IconsContainer withPadding={Icons?.length > 1}>
            {reverseIcons.map((Icon, index) => (
              <IconContainer index={index} key={`marker-tooltip-${index}`}>
                <Icon circled color={colors('white')} size={40} backgroundColor={colors('black', 'dark')} />
              </IconContainer>
            ))}
          </IconsContainer>
        </WorksiteTooltipRowHeaderLogo>
        <WorksiteTooltipRowHeaderLabel>
          <WorksiteTooltipRowHeaderLabelName>{worksiteType}</WorksiteTooltipRowHeaderLabelName>
          {isInProgress && <Tag active={isInProgress} label={labels.in_progress} />}
        </WorksiteTooltipRowHeaderLabel>
      </WorksiteTooltipRowHeader>

      <WorksiteTooltipRow>
        <WorksiteTooltipRowIcon>
          <MarkerFillIcon size={12} color={colors('orange')} />
        </WorksiteTooltipRowIcon>
        <WorksiteTooltipRowLabel>{`${labels.from_date} : ${fromDate}`}</WorksiteTooltipRowLabel>
      </WorksiteTooltipRow>
      {lastUpdate ? (
        <WorksiteTooltipRow>
          <WorksiteTooltipRowIcon>
            <TimeIcon size={12} />
          </WorksiteTooltipRowIcon>
          <WorksiteTooltipRowLabel>{`${labels.last_update} : ${lastUpdate}`}</WorksiteTooltipRowLabel>
        </WorksiteTooltipRow>
      ) : null}
      {toDate ? (
        <WorksiteTooltipRow>
          <WorksiteTooltipRowIcon>
            <MarkerFillIcon size={12} color={colors('green')} />
          </WorksiteTooltipRowIcon>
          <WorksiteTooltipRowLabel>{`${labels.to_date} : ${toDate}`}</WorksiteTooltipRowLabel>
        </WorksiteTooltipRow>
      ) : null}

      <WorksiteTooltipRowAction>
        {actions.map((action, index) => (
          <Button key={index} success thin title={action.name} onClick={action.actionCB} rounded />
        ))}
      </WorksiteTooltipRowAction>
    </WorksiteTooltipWrapper>
  );
}
