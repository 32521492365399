import get from 'lodash-es/get';
import { createSelector } from '@reduxjs/toolkit';
import { clusterContains } from '../utils';
import { buildActiveTracksFromEquipments, equipmentContains } from '../utils/equipments';
import {
  getActiveClusters,
  getBeacons,
  getClusters,
  getDevices,
  getEquipments,
  getIdFromParams,
  getSearchFilter,
} from './index';
import { getDriversWithCurrentBeaconWithAdminsAsArray } from './user';

export const getEquipment = (state, id) => state.equipments.equipmentById[id];

export const getEquipmentsAsArray = createSelector([getEquipments], (equipments) =>
  equipments.equipmentsIds.map((id) => equipments.equipmentById[id]),
);

const getEquipmentsIds = createSelector([getEquipments], (equipments) => equipments.equipmentsIds);

export const getEquipmentsStoredById = createSelector([getEquipments], (equipments) => equipments.equipmentById);

export const getEquipmentModelsById = createSelector([getEquipments], (equipments) => equipments.equipmentModelById);

export const getEquipmentModelsAsArray = createSelector([getEquipments], (equipments) =>
  equipments.equipmentModelsIds.map((id) => equipments.equipmentModelById[id]),
);

export const getVisibleEquipments = createSelector(
  [
    getActiveClusters,
    getClusters,
    getSearchFilter,
    getEquipmentsIds,
    getEquipmentsStoredById,
    getEquipmentModelsById,
    getDevices,
    getBeacons,
  ],
  (activeClusters, clusters, filter, equipmentsIds, equipmentById, equipmentModelById, devices, beacons) =>
    equipmentsIds
      .map((id) => equipmentById[id])
      .filter((e) => !activeClusters?.length || activeClusters.includes(e.clusterId))
      .map((equipment) => {
        const cluster = clusters.clusterById[equipment.clusterId];
        const device = devices.deviceById[equipment.currentDeviceId];
        let beacon = null,
          geolocalizer = device;
        if (equipment.currentBeaconUuid && equipment.currentBeaconUuid !== '') {
          beacon = beacons.beaconsById[equipment.currentBeaconUuid];
          geolocalizer = beacon;
        }

        return {
          ...equipment,
          equipmentModel: equipmentModelById[equipment.equipmentModelId],
          hidden: !equipmentContains(equipment, filter) && !clusterContains(cluster, filter),
          activeDevice: device, // Should be renamed, not dependent on search
          activeBeacon: beacon,
          cluster: cluster,
          latitude: get(geolocalizer, 'locationLatitude'),
          longitude: get(geolocalizer, 'locationLongitude'),
          locationDate: get(geolocalizer, 'locationDate'),
        };
      }),
);

export const getEquipmentData = createSelector([getIdFromParams, getVisibleEquipments], (id, visibleEquipments) => {
  const equipment = (visibleEquipments || []).find((e) => e.id === Number(id));
  if (!equipment) return null;
  return {
    ...equipment,
  };
});

export const getEquipmentActiveTracks = createSelector([getVisibleEquipments], (equipments) =>
  buildActiveTracksFromEquipments(equipments),
);

export const getEquipmentModelWithID = createSelector([getEquipments], (equipments) => {
  const map = {};
  equipments.equipmentModelsIds.forEach((equipmentModelID) => {
    const equipmentModel = equipments.equipmentModelById[equipmentModelID];
    map[equipmentModel.equipmentType] = equipmentModelID;
  });
  return map;
});

export const getActiveEquipmentsAsWorksites = createSelector(
  [getVisibleEquipments, getDriversWithCurrentBeaconWithAdminsAsArray],
  (equipments, drivers) => {
    const activeEquipments = equipments.filter((eq) => eq.status === 'IN_USE');
    const activeDrivers = drivers?.filter((driver) => driver.status === 'IN_USE');
    let activeWorksites = [];
    let parentSessionIdsList = [];
    activeEquipments.forEach((equipment) => {
      const { activeSession } = equipment;
      const relatedDrivers =
        activeDrivers.filter(
          (driver) => driver.activeSession?.parentDeviceSessionId === activeSession.parentDeviceSessionId,
        ) || [];
      if (parentSessionIdsList.find((id) => id === activeSession.parentDeviceSessionId)) {
        activeWorksites[activeSession.parentDeviceSessionId].equipment_instances.push({
          ...equipment.equipmentModel,
          equipmentModel: { type: equipment.equipmentModel?.type },
          label: equipment.label,
          id: equipment.id,
        });
        if (
          activeWorksites[activeSession.parentDeviceSessionId].equipment_model === 'tractor' &&
          equipment.equipmentModel?.type !== 'tractor'
        ) {
          activeWorksites[activeSession.parentDeviceSessionId].equipment_model = equipment.equipmentModel.type;
        }
      } else {
        activeWorksites[activeSession.parentDeviceSessionId] = {
          equipment_instances: [
            {
              ...equipment.equipmentModel,
              equipmentModel: { type: equipment.equipmentModel?.type },
              label: equipment.label,
              id: equipment.id,
            },
          ],
          equipment_model: equipment.equipmentModel?.type,
          from_date: activeSession.fromDate,
          cluster_id: equipment.clusterId,
          device_session_id: activeSession.deviceSessionId,
          equipmentLatitude: equipment.latitude,
          equipmentLongitude: equipment.longitude,
          device_session_status: 'active',
        };
        if (relatedDrivers.length) {
          let related_user_sessions = [];
          relatedDrivers.forEach((driver) => {
            related_user_sessions.push({
              user_id: driver.id,
              user_fullname: driver.fullname,
              device_instance_id: driver.activeSession.deviceInstanceId,
              beacon_uuid: driver.uuid,
              from_date: driver.activeSession.fromDate,
              device_session_uuid: driver.activeSession.deviceSessionId,
            });
          });
          activeWorksites[activeSession.parentDeviceSessionId].related_user_sessions = related_user_sessions;
        }
        parentSessionIdsList.push(activeSession.parentDeviceSessionId);
      }
    });
    return Object.values(activeWorksites);
  },
);
