import removeAccents from 'remove-accents';

export const userContains = (user, filter) => {
  const f = removeAccents(filter).toLowerCase();
  return removeAccents(getUserName(user)).toLowerCase().includes(f);
};

export const getUserPhytoNumber = (user, clusterId) => {
  if (!user || !user.cluster_roles) {
    return null;
  }
  const cluster_role = user.cluster_roles.find(({ cluster_id }) => {
    return cluster_id === clusterId;
  });
  return cluster_role?.certiphyto_number;
};

export const getUserName = (user) =>
  (user ? (!user.fullname || user.fullname === ' ' ? user.email || user.email_address : user.fullname) : '') || '';
