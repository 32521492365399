import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { receiveLogin } from '../actions/user';

function AccessTokenContainer() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (location?.hash === '' || location?.hash === undefined) {
      history.push('/');
    }
    const hashValues = (location?.hash || '').replace('#', '').split('&');
    let accessToken = 0,
      expireDate = null;

    for (let v of hashValues) {
      let keyValue = v.split('=');
      if (keyValue[0] === 'access_token') {
        accessToken = keyValue[1];
      }
      if (keyValue[0] === 'expires_in') {
        expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + +keyValue[1]);
      }
    }
    dispatch(
      receiveLogin({
        token: accessToken,
        expireDate: expireDate,
      }),
    );

    const fromURL = localStorage.getItem('fromURL');
    if (fromURL && fromURL !== '/') {
      localStorage.removeItem('fromURL');
      window.location.replace(decodeURIComponent(fromURL));
    }
  }, [dispatch, history, location]);

  useEffect(() => {
    if (user?.isAuthenticated) {
      history.push('/');
    }
  }, [history, user]);

  return null;
}

export default AccessTokenContainer;
