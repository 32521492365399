import { createSelector } from '@reduxjs/toolkit';
import { USER_CONSTANTS } from '../constants';
import { clusterContains } from '../utils';
import { userContains } from '../utils/user';
import { getDriversBeacon } from './beacons';
import { getActiveClusters, getClusters, getSearchFilter, getUsers } from './index';

export const getUsersAsArray = createSelector([getUsers], (users) => {
  const allUsers = Object.values(users);
  return allUsers.filter((user) => {
    return (user.cluster_roles || []).some((clusterRole) =>
      [
        USER_CONSTANTS.ROLE.ADMIN,
        USER_CONSTANTS.ROLE.IMPLEMENT_MANAGER,
        USER_CONSTANTS.ROLE.DRIVER,
        USER_CONSTANTS.ROLE.SEASONAL_DRIVER,
      ].includes(clusterRole.role),
    );
  });
});

const getDrivers = createSelector([getUsersAsArray], (users) => {
  return users.filter((user) => {
    return (user.cluster_roles || []).some((clusterRole) =>
      [USER_CONSTANTS.ROLE.DRIVER, USER_CONSTANTS.ROLE.SEASONAL_DRIVER].includes(clusterRole.role),
    );
  });
});

const getDriversSelector = ({ withAdmins } = { withAdmins: false }) =>
  createSelector(
    [withAdmins ? getUsersAsArray : getDrivers, getDriversBeacon, getClusters, getActiveClusters, getSearchFilter],
    (drivers, beacons, clusters, activeClusters, filter) => {
      const { clusterById } = clusters;
      const driversByID = {};
      const idToFilter = [];
      drivers.forEach((d) => {
        let beacon, beaconID, readableBeaconID, activeSession, latitude, longitude, locationDate;
        let status = 'AVAILABLE';
        beacons.forEach((b) => {
          if (b.currentUserId === d.id) {
            beacon = b;
            beaconID = b.uuid;
            readableBeaconID = b.readableUuid;
            status = b.status;
            activeSession = b.activeSession;
            latitude = b.locationLatitude;
            longitude = b.locationLongitude;
            locationDate = b.locationDate;
          }
        });
        let isDriverClusterCompliant = !activeClusters.length;
        (d.cluster_roles || []).forEach((r) => {
          if (activeClusters.includes(r.cluster_id)) isDriverClusterCompliant = true;
          const cluster = { ...clusterById[r.cluster_id], role: r.role };
          const currentBeaconUuid = d.current_beacon_uuid;
          driversByID[d.id] = {
            ...d,
            currentBeaconUuid,
            beacon,
            beaconID,
            readableBeaconID,
            hidden: !userContains(d, filter) && !clusterContains(cluster, filter),
            status,
            activeSession,
            cluster,
            latitude,
            longitude,
            locationDate,
          };
        });
        if (!isDriverClusterCompliant) idToFilter.push(d.id);
      });
      idToFilter.forEach((driverID) => delete driversByID[driverID]);
      return driversByID;
    },
  );

const getDriversWithCurrentBeacon = getDriversSelector();

export const getDriversWithCurrentBeaconAsArray = createSelector([getDriversWithCurrentBeacon], (driversByID) =>
  Object.values(driversByID),
);

export const getDriversWithCurrentBeaconWithAdmins = getDriversSelector({ withAdmins: true });

export const getDriversWithCurrentBeaconWithAdminsAsArray = createSelector(
  [getDriversWithCurrentBeaconWithAdmins],
  (driversByID) => Object.values(driversByID),
);
