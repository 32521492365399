export const MIXTURE_STATE = {
  COMPLIANT: 'ok',
  NON_COMPLIANT: 'not_ok',
  ARCHIVED: 'archived',
};

export const PRODUCT_STATE = {
  WITHDRAW: 'RETIRE',
  AUTHORIZED: 'AUTORISE',
};

export const USAGE_STATE = {
  WITHDRAW: 'Retrait',
  AUTHORIZED: 'Autorisé',
  TEMPORARILY: 'Autorisé (Provisoire)',
};

export const USAGE_ISSUES = {
  PRODUCT_WITHDRAWN: 'product_withdrawn',
  USAGE_WITHDRAWN: 'usage_withdrawn',
  OVERDOSE: 'overdose',
  UNAUTHORIZED_MIX: 'unauthorized_with_other_product',
};
