import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const MixtureIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '70%' : '100%'}
      height={circled ? '70%' : '100%'}
      viewBox="0 0 18.452 15.988"
    >
      <defs>
        <clipPath>
          <rect width="18.452" height="15.988" fill="none" stroke="rgba(0,0,0,0.87)" strokeWidth="1" />
        </clipPath>
      </defs>
      <g>
        <g clipPath="url(#clipPath)">
          <ellipse
            cx="8.726"
            cy="3.135"
            rx="8.726"
            ry="3.135"
            transform="translate(0.5 3.203)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M2.237,8.133c.9-1.3,3.686-2.239,6.989-2.239,3.02,0,5.611.789,6.717,1.914"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M17.952,6.129c0,5.169-3.907,9.359-8.726,9.359S.5,11.3.5,6.129"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <line
            x1="8.571"
            y2="8.571"
            transform="translate(9.141 0.5)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  </CircledIcon>
);
