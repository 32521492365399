import { useContext } from 'react';
import styled from 'styled-components';
import { CentralModal } from '@karnott/modals';
import { I18nContext } from '../contexts/I18nProvider';
import { UIContext } from '../contexts/ui';
import { ModalLink } from '../ui/Modal';

const Content = styled.p`
  margin: 0;
`;

const HelpDemoModal = function () {
  const { t } = useContext(I18nContext);
  const { closeDemoModal, demoModalOpened } = useContext(UIContext);

  return (
    <CentralModal
      title={t('HelpDemoModal.title')}
      showModal={demoModalOpened}
      onClose={closeDemoModal}
      onSave={closeDemoModal}
      saveLabel={t('HelpDemoModal.ok')}
      maxWidth="30em"
    >
      <Content>
        {t('HelpDemoModal.explanation_1')}
        <ModalLink href="tel:+33374094892">+33 (0)3 74 09 48 92</ModalLink>
        {t('HelpDemoModal.explanation_2')}
        <ModalLink href="mailto:support@karnott.fr">support@karnott.fr</ModalLink>
        {t('HelpDemoModal.explanation_3')}
      </Content>
    </CentralModal>
  );
};

export default HelpDemoModal;
