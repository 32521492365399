import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { elevation, fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

export const ExportWrapper = styled.div`
  display: flex;
  gap: ${pixelSpacing()};
`;

export const ReportsWithStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

export const ReportsStatsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${pixelSpacing()};
  background: rgb(35, 139, 133);
  background: linear-gradient(90deg, rgba(35, 139, 133, 1) 0%, rgba(118, 194, 44, 1) 100%);
  gap: ${pixelSpacing()};
`;

export const ReportsStatsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${pixelSpacing()};
`;

export const ReportsStatsHeaderItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ selected }) => (selected ? colors('white') : 'transparent')};
  border-radius: ${pixelSpacing()};
  font-family: ${fontFamily()};
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  color: ${({ selected }) => (selected ? colors('green') : colors('white'))};
  font-size: ${pixelSize('small')};
  cursor: pointer;
  gap: ${pixelSpacing('small')};
  > span:first-of-type {
    text-transform: uppercase;
  }
  > span {
    padding: ${pixelSpacing('small')} 0;
  }
`;

export const ReportsStatsContent = styled.div`
  display: flex;
  align-items: stretch;
  gap: ${pixelSpacing('small')};
  @media (max-width: 1366px) {
    flex-direction: column;
  }
`;

export const WorksitesReportsCharts = styled.div`
  display: flex;
  flex: 4;
  gap: ${pixelSpacing('small')};
`;

export const WorksitesReportsNumericalValues = styled.div`
  display: flex;
  flex: 1;
  gap: ${pixelSpacing('small')};
`;

export const StatsContentBloc = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${({ weight }) => weight || 1};
  gap: ${pixelSpacing('small')};
  ${({ elevated }) => (elevated ? elevation({ elevated: true }) : null)}
  > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${colors('white')};
    border-radius: 4px;
    padding: ${pixelSpacing()};
  }
`;

export const StatsTitle = styled.div`
  font-size: ${pixelSize('large')};
  font-family: ${fontFamily()};
  font-weight: 700;
  margin-bottom: ${pixelSpacing('small')};
`;

export const StatsSimpleNumericValue = styled.div`
  white-space: nowrap;
  font-size: 32px;
  font-family: ${fontFamily()};
  font-weight: 700;
`;

export const StatsCircularChart = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  max-width: 100%;
  overflow: hidden;
  padding: 2px;
  margin-top: ${({ withMarginTop }) => (withMarginTop ? pixelSpacing('small') : 0)};
  > * {
    min-height: 200px;
    max-height: 200px;
    box-shadow: none;
  }
`;
