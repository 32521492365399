import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';

export const Actions = {
  // Clusters
  CLUSTERS_REQUEST: 'CLUSTERS_REQUEST',
  CLUSTERS_SUCCESS: 'CLUSTERS_SUCCESS',
  CLUSTER_SUCCESS: 'CLUSTER_SUCCESS',
  CLUSTER_UNLINKED: 'CLUSTER_UNLINKED',
  CLUSTER_TO_EDIT: 'CLUSTER_TO_EDIT',
  CLEAR_CLUSTER_TO_EDIT: 'CLEAR_CLUSTER_TO_EDIT',
  USER_UNLINKED: 'USER_UNLINKED',
  CLUSTERS_PARTNERS_RETRIEVED: 'CLUSTERS_PARTNERS_RETRIEVED',
  SELECT_ALL_CLUSTERS: 'SELECT_ALL_CLUSTERS',
  DESELECT_ALL_CLUSTERS: 'DESELECT_ALL_CLUSTERS',

  // Sessions
  RECEIVE_PARCEL_SESSION_SUCCESS: 'RECEIVE_PARCEL_SESSION_SUCCESS',
  PARCEL_SESSIONS_SUCCESS: 'PARCEL_SESSIONS_SUCCESS',
  PARCEL_SESSION_POINTS_SUCCESS: 'PARCEL_SESSION_POINTS_SUCCESS',
  EQUIPMENT_SESSION_PARCEL_SESSIONS_SUCCESS: 'EQUIPMENT_SESSION_PARCEL_SESSIONS_SUCCESS',
  OVER_SESSION: 'OVER_SESSION',
  REQUEST_SHARED_SESSION: 'REQUEST_SHARED_SESSION',
  RECEIVE_SHARED_SESSION: 'RECEIVE_SHARED_SESSION',
  UPDATED_SHARED_SESSION: 'UPDATED_SHARED_SESSION',
  FAIL_SHARED_SESSION: 'FAIL_SHARED_SESSION',
  GET_FORCED_OR_IGNORED_PARCELS_OF_DEVICE_SESSION_SUCCESS: 'GET_FORCED_OR_IGNORED_PARCELS_OF_DEVICE_SESSION_SUCCESS',
  UPDATE_EQUIPMENT_SESSION_STATUS: 'UPDATE_EQUIPMENT_SESSION_STATUS',

  // Equipments
  EQUIPMENTS_REQUEST: 'EQUIPMENTS_REQUEST',
  EQUIPMENTS_SUCCESS: 'EQUIPMENTS_SUCCESS',
  EQUIPMENT_SUCCESS: 'EQUIPMENT_SUCCESS',
  BLUR_EQUIPMENT: 'BLUR_EQUIPMENT',
  FOCUS_EQUIPMENT: 'FOCUS_EQUIPMENT',
  EQUIPMENT_MODELS_REQUEST: 'EQUIPMENT_MODELS_REQUEST',
  EQUIPMENT_MODELS_SUCCESS: 'EQUIPMENT_MODELS_SUCCESS',
  EQUIPMENT_CREATION_REQUEST: 'EQUIPMENT_CREATION_REQUEST',
  EQUIPMENT_CREATION_SUCCESS: 'EQUIPMENT_CREATION_SUCCESS',
  EQUIPMENT_SESSIONS_REQUEST: 'EQUIPMENT_SESSIONS_REQUEST',
  EQUIPMENT_SESSIONS_SUCCESS: 'EQUIPMENT_SESSIONS_SUCCESS',
  EQUIPMENT_SESSION_SUCCESS: 'EQUIPMENT_SESSION_SUCCESS',
  EQUIPMENT_SESSION_POINTS_SUCCESS: 'EQUIPMENT_SESSION_POINTS_SUCCESS',
  EQUIPMENT_DELETE_REQUEST: 'EQUIPMENT_DELETE_REQUEST',
  EQUIPMENT_DELETE_SUCCESS: 'EQUIPMENT_DELETE_SUCCESS',
  REMOVE_CURRENT_DEVICE_TO_EQUIPMENT: 'REMOVE_CURRENT_DEVICE_TO_EQUIPMENT',
  EQUIPMENT_LINK_EXISTING_TAG: 'EQUIPMENT_LINK_EXISTING_TAG',
  EQUIPMENT_UNLINK_TAG: 'EQUIPMENT_UNLINK_TAG',

  // Parcels
  PARCELS_REQUEST: 'PARCELS_REQUEST',
  PARCELS_SUCCESS: 'PARCELS_SUCCESS',
  PARCEL_REQUEST: 'PARCEL_REQUEST',
  PARCEL_SUCCESS: 'PARCEL_SUCCESS',
  PARCEL_FOCUS: 'PARCEL_FOCUS',
  PARCEL_CREATION_REQUEST: 'PARCEL_CREATION_REQUEST',
  PARCEL_CREATION_SUCCESS: 'PARCEL_CREATION_SUCCESS',
  PARCEL_CREATION_ERRORS: 'PARCEL_CREATION_ERRORS',
  PARCEL_CREATION_CLEAR_ERRORS: 'PARCEL_CREATION_CLEAR_ERRORS',
  PARCEL_EDIT_REQUEST: 'PARCEL_EDIT_REQUEST',
  PARCEL_EDIT_SUCCESS: 'PARCEL_EDIT_SUCCESS',
  PARCEL_DELETE_REQUEST: 'PARCEL_DELETE_REQUEST',
  PARCEL_DELETE_SUCCESS: 'PARCEL_DELETE_SUCCESS',
  MULTI_PARCEL_DELETE_SUCCESS: 'MULTI_PARCEL_DELETE_SUCCESS',
  REQUEST_IMPORT_PARCELS: 'REQUEST_IMPORT_PARCELS',
  RECEIVE_IMPORTED_PARCELS: 'RECEIVE_IMPORTED_PARCELS',
  REINIT_IMPORTED_PARCELS: 'REINIT_IMPORTED_PARCELS',
  REQUEST_PARCEL_SESSIONS_BY_IDS: 'REQUEST_PARCEL_SESSIONS_BY_IDS',
  REQUEST_EQUIPMENT_SESSIONS_BY_IDS: 'REQUEST_EQUIPMENT_SESSIONS_BY_IDS',
  RECEIVE_PARCEL_SESSIONS_BY_IDS: 'RECEIVE_PARCEL_SESSIONS_BY_IDS',
  RECEIVE_EQUIPMENT_SESSIONS_BY_IDS: 'RECEIVE_EQUIPMENT_SESSIONS_BY_IDS',
  NEW_CLUSTER_ID_FOR_SESSION: 'NEW_CLUSTER_ID_FOR_SESSION',
  REQUEST_GFR_PARCELS: 'REQUEST_GFR_PARCELS',
  GFR_PARCELS_SUCCESS: 'GFR_PARCELS_SUCCESS',
  SELECT_MULTIPLE_PARCELS_TO_DELETE: 'SELECT_MULTIPLE_PARCELS_TO_DELETE',
  DESELECT_MULTIPLE_PARCELS_TO_DELETE: 'DESELECT_MULTIPLE_PARCELS_TO_DELETE',
  GFR_PARCELS_CREATION_REQUEST: 'GFR_PARCELS_CREATION_REQUEST',
  GFR_PARCELS_CREATION_SUCCESS: 'GFR_PARCELS_CREATION_SUCCESS',
  PARCEL_HISTORY_REQUEST: 'PARCEL_HISTORY_REQUEST',
  PARCEL_HISTORY_SUCCESS: 'PARCEL_HISTORY_SUCCESS',
  PARCEL_RENT_CREATION_REQUEST: 'PARCEL_RENT_CREATION_REQUEST',
  UPDATE_CLUSTER_PARCEL_REQUEST: 'UPDATE_CLUSTER_PARCEL_REQUEST',
  COLORIZE_PARCELS_BY: 'COLORIZE_PARCEL_BY',
  COLORIZE_TRACKS_BY: 'COLORIZE_TRACKS_BY',
  PARCELS_WORKSITES_SUCCESS: 'PARCELS_WORKSITES_SUCCESS',
  PARCELS_WORKSITES_TRACKS_PENDING: 'PARCELS_WORKSITES_TRACKS_PENDING',
  PARCELS_WORKSITES_TRACKS_SUCCESS: 'PARCELS_WORKSITES_TRACKS_SUCCESS',
  PARCELS_WORKSITES_TRACKS_FAIL: 'PARCELS_WORKSITES_TRACKS_FAIL',
  PARCEL_WORKSITES_SUCCESS: 'PARCEL_WORKSITES_SUCCESS',
  PARCEL_WORKSITES_TRACKS_SUCCESS: 'PARCEL_WORKSITES_TRACKS_SUCCESS',
  PARCEL_ACTIVE_WORKSITES_TRACKS_SUCCESS: 'PARCEL_ACTIVE_WORKSITES_TRACKS_SUCCESS',
  PARCEL_CONSUMPTIONS_SUCCESS: 'PARCEL_CONSUMPTIONS_SUCCESS',
  PARCEL_LINK_EXISTING_TAG: 'PARCEL_LINK_EXISTING_TAG',
  PARCEL_UNLINK_TAG: 'PARCEL_UNLINK_TAG',

  // Crops
  PARCEL_CROPS_HISTORY_SUCCESS: 'PARCEL_CROPS_HISTORY_SUCCESS',
  MULTI_PARCEL_CROPS_HISTORY_SUCCESS: 'MULTI_PARCEL_CROPS_HISTORY_SUCCESS',
  SELECT_ALL_CROPS: 'SELECT_ALL_CROPS',
  DESELECT_ALL_CROPS: 'DESELECT_ALL_CROPS',

  // Devices
  DEVICE_HISTORY_SUCCESS: 'DEVICE_HISTORY_SUCCESS',
  DEVICES_REQUEST: 'DEVICES_REQUEST',
  DEVICES_SUCCESS: 'DEVICES_SUCCESS',
  DEVICE_EDIT_REQUEST: 'DEVICE_EDIT_REQUEST',
  DEVICE_EDIT_SUCCESS: 'DEVICE_EDIT_SUCCESS',
  DEVICE_ATTACH_EQUIPMENT_REQUEST: 'DEVICE_ATTACH_EQUIPMENT_REQUEST',
  DEVICE_ATTACH_EQUIPMENT_SUCCESS: 'DEVICE_ATTACH_EQUIPMENT_SUCCESS',
  DEVICE_SUCCESS: 'DEVICE_SUCCESS',
  EQUIPMENT_HAS_DEVICE_DELETE_SUCCESS: 'EQUIPMENT_HAS_DEVICE_DELETE_SUCCESS',
  EQUIPMENT_HAS_DEVICE_EDIT_SUCCESS: 'EQUIPMENT_HAS_DEVICE_EDIT_SUCCESS',
  DEVICE_LINK_EXISTING_TAG: 'DEVICE_LINK_EXISTING_TAG',
  DEVICE_UNLINK_TAG: 'DEVICE_UNLINK_TAG',

  // Beacons
  BEACON_SUCCESS: 'BEACON_SUCCESS',
  BEACONS_SUCCESS: 'BEACONS_SUCCESS',
  BEACON_SUCCESSFULLY_LINKED: 'BEACON_SUCCESSFULLY_LINKED',
  BEACON_SUCCESSFULLY_LINKED_TO_DRIVER: 'BEACON_SUCCESSFULLY_LINKED_TO_DRIVER',
  BEACON_DRIVER_SUCCESSFULLY_UNLINKED: 'BEACON_DRIVER_SUCCESSFULLY_UNLINKED',
  BEACON_EQUIPMENT_HISTORY_SUCCESS: 'BEACON_EQUIPMENT_HISTORY_SUCCESS',
  BEACON_DRIVER_HISTORY_SUCCESS: 'BEACON_DRIVER_HISTORY_SUCCESS',
  BEACON_DRIVER_REMOVE_DRIVER: 'BEACON_DRIVER_REMOVE_DRIVER',
  BEACON_LINK_EXISTING_TAG: 'BEACON_LINK_EXISTING_TAG',
  BEACON_UNLINK_TAG: 'BEACON_UNLINK_TAG',

  // Filters
  MODIFY_EQUIPMENT_FILTERS: 'MODIFY_EQUIPMENT_FILTERS',
  MODIFY_PARCELS_FILTERS: 'MODIFY_PARCELS_FILTERS',
  MODIFY_KARNOTT_FILTERS: 'MODIFY_KARNOTT_FILTERS',
  MODIFY_BEACON_FILTERS: 'MODIFY_BEACON_FILTERS',
  MODIFY_DRIVER_BEACON_FILTERS: 'MODIFY_DRIVER_BEACON_FILTERS',
  MODIFY_DRIVER_FILTERS: 'MODIFY_DRIVERS_FILTERS',
  REINIT_EQUIPMENTS_FILTERS: 'REINIT_EQUIPMENTS_FILTERS',
  REINIT_PARCELS_FILTERS: 'REINIT_PARCELS_FILTERS',
  REINIT_KARNOTT_FILTERS: 'REINIT_KARNOTT_FILTERS',
  REINIT_BEACON_FILTERS: 'REINIT_BEACON_FILTERS',
  REINIT_DRIVER_BEACON_FILTERS: 'REINIT_DRIVER_BEACON_FILTERS',
  REINIT_DRIVERS_FILTERS: 'REINIT_DRIVERS_FILTERS',
  CLOSE_FILTERS: 'CLOSE_FILTERS',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS',
  SET_EQUIPMENT_SESSIONS_FILTERS: 'SET_EQUIPMENT_SESSIONS_FILTERS',
  SET_DEFAULT_TAG_FILTERS: 'SET_DEFAULT_TAG_FILTERS',

  // Reports
  REPORTS_EQUIPMENTS_REPORT_REQUEST: 'REPORTS_EQUIPMENTS_REPORT_REQUEST',
  REPORTS_PARCELS_REPORT_REQUEST: 'REPORTS_PARCELS_REPORT_REQUEST',
  REPORTS_DRIVERS_REPORT_REQUEST: 'REPORTS_DRIVERS_REPORT_REQUEST',
  REPORTS_DRIVERS_TRACKS_REQUEST: 'REPORTS_DRIVERS_TRACKS_REQUEST',
  REPORTS_EQUIPMENTS_TRACKS_REPORT_REQUEST: 'REPORTS_EQUIPMENTS_TRACKS_REPORT_REQUEST',
  REPORTS_PARCELS_TRACKS_REPORT_REQUEST: 'REPORTS_PARCELS_TRACKS_REPORT_REQUEST',
  REPORTS_EQUIPMENTS_REPORT_TRACKS_FAIL: 'REPORTS_EQUIPMENTS_REPORT_TRACKS_FAIL',
  REPORTS_PARCELS_REPORT_TRACKS_FAIL: 'REPORTS_PARCELS_REPORT_TRACKS_FAIL',
  REPORTS_EQUIPMENTS_REPORT_SUCCESS: 'REPORTS_EQUIPMENTS_REPORT_SUCCESS',
  REPORTS_PARCELS_REPORT_SUCCESS: 'REPORTS_PARCELS_REPORT_SUCCESS',
  REPORTS_DRIVERS_REPORT_SUCCESS: 'REPORTS_DRIVERS_REPORT_SUCCESS',
  REPORTS_DRIVERS_TRACKS_SUCCESS: 'REPORTS_DRIVERS_TRACKS_SUCCESS',
  REPORTS_EQUIPMENTS_TRACKS_REPORT_SUCCESS: 'REPORTS_EQUIPMENTS_TRACKS_REPORT_SUCCESS',
  REPORTS_PARCELS_TRACKS_REPORT_SUCCESS: 'REPORTS_PARCELS_TRACKS_REPORT_SUCCESS',
  MODIFY_EQUIPMENTS_REPORTS_PREFERRED_DIMENSIONS: 'MODIFY_EQUIPMENTS_REPORTS_PREFERRED_DIMENSIONS',
  MODIFY_PARCELS_REPORTS_PREFERRED_DIMENSIONS: 'MODIFY_PARCELS_REPORTS_PREFERRED_DIMENSIONS',
  RESET_GEOJSON_TRACKS: 'RESET_GEOJSON_TRACKS',
  REPORTS_OBSERVATIONS_SUCCESS: 'REPORTS_OBSERVATIONS_SUCCESS',
  REPORTS_REQUEST_OBSERVATIONS: 'REPORTS_REQUEST_OBSERVATIONS',
  WORKSITES_SUCCESS: 'WORKSITES_SUCCESS',
  WORKSITES_TRACKS_PENDING: 'WORKSITES_TRACKS_PENDING',
  WORKSITES_TRACKS_SUCCESS: 'WORKSITES_TRACKS_SUCCESS',
  WORKSITES_TRACKS_FAIL: 'WORKSITES_TRACKS_FAIL',
  SET_WORKSITE_REPORT_INITIAL_EQUIPMENT_MODEL: 'SET_WORKSITE_REPORT_INITIAL_EQUIPMENT_MODEL',
  SET_WORKSITE_REPORT_BY_CLUSTER_INITIAL_CLUSTER: 'SET_WORKSITE_REPORT_BY_CLUSTER_INITIAL_CLUSTER',
  REPORTS_REQUEST_PHYTO: 'REPORTS_REQUEST_PHYTO',
  REPORTS_PHYTO_SUCCESS: 'REPORTS_PHYTO_SUCCESS',

  // UI
  SELECT_CLUSTERS: 'SELECT_CLUSTERS',
  CLEAR_CLUSTERS: 'CLEAR_CLUSTERS',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  UPDATE_TITLE: 'UPDATE_TITLE',
  DATE_META_MODIFIED: 'DATE_META_MODIFIED',
  TOGGLE_FULLSCREEN: 'TOGGLE_FULLSCREEN',
  TOGGLE_FULLSCREEN_DASHBOARD: 'TOGGLE_FULLSCREEN_DASHBOARD',
  SET_SHOW_SESSION_REPLAY: 'SET_SHOW_SESSION_REPLAY',
  TOGGLE_PARCEL_SESSIONS_TRACKS: 'TOGGLE_PARCEL_SESSIONS_TRACKS',
  TOGGLE_EQUIPMENT_SESSIONS_TRACKS: 'TOGGLE_EQUIPMENT_SESSIONS_TRACKS',
  SET_PARCELS_BOXES_OFFSET: 'SET_PARCELS_BOXES_OFFSET',
  SET_EQUIPMENTS_BOXES_OFFSET: 'SET_EQUIPMENTS_BOXES_OFFSET',
  SET_EQUIPMENT_PAGE_OFFSET: 'SET_EQUIPMENT_PAGE_OFFSET',
  SET_PARCEL_PAGE_OFFSET: 'SET_PARCEL_PAGE_OFFSET',
  SET_WORKED_PARCELS_PAGE_OFFSET: 'SET_WORKED_PARCELS_PAGE_OFFSET',
  TOGGLE_PARCEL_NAMES: 'TOGGLE_PARCEL_NAMES',
  TOGGLE_MARKER_CLUSTERS: 'TOGGLE_MARKER_CLUSTERS',
  TOGGLE_USE_IGN_TILES: 'TOGGLE_USE_IGN_TILES',
  TOGGLE_PARCEL_TYPES: 'TOGGLE_PARCEL_TYPES',
  SET_PARCEL_TYPES: 'SET_PARCEL_TYPES',
  CLEAR_PARCEL_TYPES: 'CLEAR_PARCEL_TYPES',
  TOGGLE_CROPS: 'TOGGLE_CROPS',
  SET_CROPS: 'SET_CROPS',
  CLEAR_CROPS: 'CLEAR_CROPS',
  SET_AREA_RANGE: 'SET_AREA_RANGE',
  CLEAR_AREA_RANGE: 'CLEAR_AREA_RANG',
  TOGGLE_TAGS: 'TOGGLE_TAGS',
  SET_TAGS: 'SET_TAGS',
  SET_DEFAULT_PARCEL_TAGS: 'SET_DEFAULT_PARCEL_TAGS',
  RESET_TAGS: 'RESET_TAGS',
  TOGGLE_PARCEL_IDS: 'TOGGLE_PARCEL_IDS',
  SET_PARCEL_IDS: 'SET_PARCEL_IDS',
  CLEAR_PARCEL_IDS: 'CLEAR_PARCEL_IDS',
  HIDE_INTERCOM_MESSENGER: 'HIDE_INTERCOM_MESSENGER',

  // User
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  USER_INFO_RETRIEVED: 'USER_INFO_RETRIEVED',
  LOGOUT: 'LOGOUT',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  RETRIEVE_USERS_OF_CLUSTER_SUCCESS: 'RETRIEVE_USERS_OF_CLUSTER_SUCCESS',
  RETRIEVE_USERS_OF_CLUSTERS_SUCCESS: 'RETRIEVE_USERS_OF_CLUSTERS_SUCCESS',
  USER_LINK_SUCCESS: 'USER_LINK_SUCCESS',
  DRIVER_AND_BEACON_LINKED: 'DRIVER_AND_BEACON_LINKED',
  RECEIVE_USERS: 'RECEIVE_USERS',
  DRIVER_FOCUS: 'DRIVER_FOCUS',
  PARTNER_TOKEN_SUCCESS: 'PARTNER_TOKEN_SUCCESS',
  HAS_PARTNER_TOKEN_SUCCESS: 'HAS_PARTNER_TOKEN_SUCCESS',
  PARTNER_FARMS_SYNC_SUCCESS: 'PARTNER_FARMS_SYNC_SUCCESS',
  GET_SYNCED_PARTNER_FARMS_SUCCESS: 'GET_SYNCED_PARTNER_FARMS_SUCCESS',
  UPDATE_PARTNER_FARM_SUCCESS: 'UPDATE_PARTNER_FARM_SUCCESS',
  PARTNER_CROP_ZONES_SYNC_SUCCESS: 'PARTNER_CROP_ZONES_SYNC_SUCCESS',
  GET_PARTNER_CROP_ZONES_SUCCESS: 'GET_PARTNER_CROP_ZONES_SUCCESS',
  UPDATE_PARTNER_CROP_ZONE_SUCCESS: 'UPDATE_PARTNER_CROP_ZONE_SUCCESS',
  USER_LINK_EXISTING_TAG: 'USER_LINK_EXISTING_TAG',
  USER_UNLINK_TAG: 'USER_UNLINK_TAG',

  // Observations
  OBSERVATIONS_SUCCESS: 'OBSERVATIONS_SUCCESS',
  OBSERVATIONS_ADD: 'OBSERVATIONS_ADD',
  OBSERVATIONS_UPDATE: 'OBSERVATIONS_UPDATE',
  OBSERVATIONS_DELETE: 'OBSERVATIONS_DELETE',
  OBSERVATIONS_DELETE_FILES: 'OBSERVATIONS_DELETE_FILES',
  OBSERVATIONS_ADD_FILES: 'OBSERVATIONS_ADD_FILES',
  OBSERVATIONS_EQUIPMENT_SUCCESS: 'OBSERVATIONS_EQUIPMENT_SUCCESS',
  OBSERVATIONS_EQUIPMENT_SESSION_SUCCESS: 'OBSERVATIONS_EQUIPMENT_SESSION_SUCCESS',
  OBSERVATIONS_PARCEL_SUCCESS: 'OBSERVATIONS_PARCEL_SUCCESS',
  OBSERVATION_FOCUS: 'OBSERVATION_FOCUS',
  OBSERVATION_CATEGORIES_SUCCESS: 'OBSERVATION_CATEGORIES_SUCCESS',
  OBSERVATION_CATEGORY_CREATE_SUCCESS: 'OBSERVATION_CATEGORY_CREATE_SUCCESS',
  OBSERVATION_CATEGORY_UPDATED: 'OBSERVATION_CATEGORY_UPDATED',
  OBSERVATION_CATEGORY_DELETED: 'OBSERVATION_CATEGORY_DELETED',
  OBSERVATIONS_RESTORED_SUCCESS: 'OBSERVATIONS_RESTORED_SUCCESS',
  OBSERVATIONS_ARCHIVED_SUCCESS: 'OBSERVATIONS_ARCHIVED_SUCCESS',
  OBSERVATIONS_DELETED_SUCCESS: 'OBSERVATIONS_DELETED_SUCCESS',

  // DRIVERS
  BLUR_DRIVER: 'BLUR_DRIVER',
  FOCUS_DRIVER: 'FOCUS_DRIVER',

  //WORKSITES
  BLUR_WORKSITE: 'BLUR_WORKSITE',
  FOCUS_WORKSITE: 'FOCUS_WORKSITE',

  //TOOLTIPS
  HIDE_EQUIPMENT_TOOLTIP: 'HIDE_EQUIPMENT_TOOLTIP',
  HIDE_DRIVER_TOOLTIP: 'HIDE_DRIVER_TOOLTIP',

  //PHYTO
  GET_PHYTO_FAVORITE_PRODUCT: 'GET_PHYTO_FAVORITE_PRODUCT',
  GET_PHYTO_FAVORITE_PRODUCTS: 'GET_PHYTO_FAVORITE_PRODUCTS',
  CREATE_PHYTO_PRODUCT: 'CREATE_PHYTO_PRODUCT',
  UPDATE_PHYTO_PRODUCT: 'UPDATE_PHYTO_PRODUCT',
  DELETE_PHYTO_PRODUCT: 'DELETE_PHYTO_PRODUCT',
  GET_PHYTO_MIXTURES: 'GET_PHYTO_MIXTURES',
  CREATE_PHYTO_MIXTURE: 'CREATE_PHYTO_MIXTURE',
  UPDATE_PHYTO_MIXTURE: 'UPDATE_PHYTO_MIXTURE',
  DELETE_PHYTO_MIXTURE: 'DELETE_PHYTO_MIXTURE',

  // TASKS
  GET_TASK: 'GET_TASK',
  GET_TASKS: 'GET_TASKS',
  DELETE_TASK: 'DELETE_TASK',
  TASKS_LOADING: 'TASKS_LOADING',
  GET_TASK_METADATA: 'GET_TASK_METADATA',
  TASK_EMAIL_SENT: 'TASK_EMAIL_SENT',
};

export const errorHasOccurred = (e, dispatch) => {
  dispatch(
    snackAdded({
      type: SNACK_CONSTANTS.TYPE.ERROR,
      duration: 5000,
      message: I18n.t('Api.failedCall'),
      action: SNACK_CONSTANTS.ACTION.OK,
    }),
  );
  return Promise.reject(e);
};
