import { replace } from 'redux-first-history';
import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';
import { userLinkedWithSuccess } from './user';

const requestClusters = () => ({
  type: Actions.CLUSTERS_REQUEST,
});

const receiveClusters = (payload) => ({
  type: Actions.CLUSTERS_SUCCESS,
  payload,
});

export const receiveCluster = (payload) => ({
  type: Actions.CLUSTER_SUCCESS,
  payload,
});

export const updateCluster =
  (cluster, withRedirection = true) =>
  (dispatch) =>
    Api.updateCluster(cluster).then(
      () => {
        dispatch(receiveCluster(cluster));
        withRedirection && dispatch(replace('/admin'));
      },
      (e) => errorHasOccurred(e, dispatch),
    );

export const patchClusterImage = (clusterId, image) => () => {
  if (!image) return Promise.reject('no file provided');
  return Api.updateClusterImage(clusterId, image).then(() => {
    return Promise.resolve();
  });
};

export const deleteClusterImage = (clusterId) => () => {
  return Api.deleteClusterImage(clusterId).then(() => {
    return Promise.resolve();
  });
};

const creationAndLinkForClusterOk = (link, addedCluster, email) => (dispatch) => {
  dispatch(
    snackAdded({
      type: 'success',
      duration: 4000,
      message: I18n.t('Clusters.user_linked_successfully', { email }),
      action: 'OK',
    }),
  );
  dispatch(userLinkedWithSuccess(link, addedCluster.id));
};

const clusterLinkedWithSuccess = (addedCluster, email) => async (dispatch) => {
  dispatch(receiveCluster(addedCluster));
  if (email) {
    const link = await Api.linkUserToClusterByMail({ email, clusterID: addedCluster.id });
    try {
      creationAndLinkForClusterOk(link, addedCluster, email)(dispatch);
    } catch (e) {
      return errorHasOccurred(e, dispatch);
    }
  }
};

const clusterCreatedWithSuccess = (addedCluster, clusterIds, email, redirectToAdmin) => (dispatch) => {
  if (redirectToAdmin) {
    dispatch(replace('/admin'));
  }
  clusterIds.forEach(async (clusterId) => {
    await Api.linkCluster(clusterId, addedCluster.id);
  });
  try {
    clusterLinkedWithSuccess(addedCluster, email)(dispatch);
  } catch (e) {
    return errorHasOccurred(e, dispatch);
  }
};

export const createCluster = (cluster, clusterIds, email, redirectToAdmin) => async (dispatch) => {
  const addedCluster = await Api.createCluster(cluster);
  try {
    await clusterCreatedWithSuccess(addedCluster, clusterIds, email, redirectToAdmin)(dispatch);
    return addedCluster.id;
  } catch (e) {
    return errorHasOccurred(e, dispatch);
  }
};

export const fetchClusters = (payload) => (dispatch) => {
  dispatch(requestClusters());

  return Api.getClusters(payload).then(
    (response) => {
      if (response.length === 0) {
        dispatch(replace('/unlinked'));
      }
      dispatch(receiveClusters(response));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const clusterSuccessfullyUnlinked = (clusterId) => ({
  type: Actions.CLUSTER_UNLINKED,
  payload: clusterId,
});

export const unlinkCluster = (parentId, childId) => (dispatch) =>
  Api.unlinkCluster(parentId, childId).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: I18n.t('Admin.unlink_cluster'),
          action: 'OK',
        }),
      );
      dispatch(clusterSuccessfullyUnlinked(childId));
      return childId;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

export const fetchClusterHierarchy = (cluster_id) => () => Api.getClusterHierarchy(cluster_id);

const userSuccessfullyUnlinked = (clusterID, userID) => ({
  type: Actions.USER_UNLINKED,
  payload: {
    clusterID,
    userID,
  },
});

export const unlinkUser = (clusterID, userID) => (dispatch) =>
  Api.unlinkUser(clusterID, userID).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: I18n.t('Admin.unlink_user_successful'),
          action: 'OK',
        }),
      );
      dispatch(userSuccessfullyUnlinked(clusterID, userID));
      return [clusterID, userID];
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const clustersPartnersRetrieved = (payload) => ({
  type: Actions.CLUSTERS_PARTNERS_RETRIEVED,
  payload,
});

export const fetchClustersPartners = () => (dispatch) =>
  Api.getClustersPartners().then(
    (payload) => dispatch(clustersPartnersRetrieved(payload)),
    (e) => errorHasOccurred(e, dispatch),
  );
