import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { deviceInstance, equipmentHasDevice } from '../api/schema';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';
import { normalize } from '../utils';

const requestDevices = (refresh) => ({
  type: Actions.DEVICES_REQUEST,
  payload: { refresh },
});

const receiveDeviceHistory = (payload) => ({
  type: Actions.DEVICE_HISTORY_SUCCESS,
  payload,
});

const receiveDevices = (payload) => ({
  type: Actions.DEVICES_SUCCESS,
  payload,
});

const requestEditDevice = (id) => ({
  type: Actions.DEVICE_EDIT_REQUEST,
  payload: {
    id,
  },
});

const receiveEditDevice = (payload) => ({
  type: Actions.DEVICE_EDIT_SUCCESS,
  payload,
});

const requestAttachDeviceEquipment = (id) => ({
  type: Actions.DEVICE_ATTACH_EQUIPMENT_REQUEST,
  payload: {
    id,
  },
});

const receiveAttachDeviceEquipment = (payload) => ({
  type: Actions.DEVICE_ATTACH_EQUIPMENT_SUCCESS,
  payload,
});

export const fetchDeviceHistory = (id) => (dispatch) =>
  Api.getDeviceHistory(id).then(
    (response) => dispatch(receiveDeviceHistory(normalize(response, [equipmentHasDevice]))),
    (e) => errorHasOccurred(e, dispatch),
  );

export const fetchDevices =
  (refresh = true) =>
  (dispatch) => {
    dispatch(requestDevices(refresh));

    return Api.getDevices().then(
      (response) => dispatch(receiveDevices(normalize(response, [deviceInstance]))),
      (e) => errorHasOccurred(e, dispatch),
    );
  };

const receiveDevice = (id, data) => ({
  type: Actions.DEVICE_SUCCESS,
  payload: { id, data },
});

export const fetchDevice = (id) => (dispatch) =>
  Api.getDevice(id).then(
    (response) => dispatch(receiveDevice(id, normalize(response, deviceInstance))),
    (e) => errorHasOccurred(e, dispatch),
  );

export const fetchDeviceSessionPoints = (deviceSessionId) => (dispatch) =>
  Api.getDeviceSessionPoints(deviceSessionId).then(
    (response) => Promise.resolve(response),
    (e) => errorHasOccurred(e, dispatch),
  );

export const editDevice = (id, payload) => (dispatch) => {
  dispatch(requestEditDevice(id));

  return Api.editDevice(id, payload).then(
    (response) => {
      dispatch(receiveEditDevice(normalize(response, deviceInstance)));
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: I18n.t('Device.update_ok'),
          action: 'OK',
        }),
      );
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const attachDeviceEquipment = (deviceId, payload) => (dispatch) => {
  dispatch(requestAttachDeviceEquipment());

  return Api.attachDeviceEquipment(deviceId, payload).then(
    (response) => {
      dispatch(receiveAttachDeviceEquipment(normalize(response, [equipmentHasDevice])));
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: 'Le matériel a été associé avec succès',
          action: 'OK',
        }),
      );
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const detachDeviceEquipment = (deviceId) => (dispatch) =>
  Api.detachDeviceEquipment(deviceId).then(
    (response) => {
      dispatch(receiveAttachDeviceEquipment(normalize(response, [equipmentHasDevice])));
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: 'Le matériel a été désassocié avec succès',
          action: 'OK',
        }),
      );
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const receiveDeleteEquipmentHasDevice = (id) => ({
  type: Actions.EQUIPMENT_HAS_DEVICE_DELETE_SUCCESS,
  payload: id,
});

export const deleteEquipmentHasDevice = (id) => (dispatch) =>
  Api.deleteEquipmentHasDevice(id).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: I18n.t('EquipmentHasDevice.delete_success'),
          action: 'OK',
        }),
      );
      return dispatch(receiveDeleteEquipmentHasDevice(id));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const receiveEditEquipmentHasDevice = (id, deviceId) => ({
  type: Actions.EQUIPMENT_HAS_DEVICE_EDIT_SUCCESS,
  payload: {
    id,
    deviceId,
  },
});

export const updateEquipmentHasDevice = (id, payload, deviceId) => (dispatch) =>
  Api.updateEquipmentHasDevice(id, payload).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 30000,
          message: I18n.t('EquipmentHasDevice.update_success'),
          action: 'OK',
        }),
      );
      dispatch(receiveEditEquipmentHasDevice(id, deviceId));
      // need to get device history because of possible merge
      return dispatch(fetchDeviceHistory(deviceId));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

// Tags

const linkedExistingTagToDevice = (deviceId, tag) => ({
  type: Actions.DEVICE_LINK_EXISTING_TAG,
  payload: { deviceId, tag },
});

const unlinkedTagFromDevice = (deviceId, tagId) => ({
  type: Actions.DEVICE_UNLINK_TAG,
  payload: { deviceId, tagId },
});

export const linkTagToDevice = (deviceId, tag) => (dispatch) => {
  return Api.linkTagToDevice(deviceId, tag.id)
    .then(() => dispatch(linkedExistingTagToDevice(deviceId, tag)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const unlinkTagFromDevice = (deviceId, tagId) => (dispatch) => {
  return Api.unlinkTagFromDevice(deviceId, tagId)
    .then(() => dispatch(unlinkedTagFromDevice(deviceId, tagId)))
    .catch((e) => errorHasOccurred(e, dispatch));
};
