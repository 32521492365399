import { L as Leaflet } from '../utils/LeafletOverrides';

export const IGN_MAX_ZOOM = 19;
export const GOOGLE_MAX_ZOOM = 20;

export const GOOGLE_MUTANT_TILES = Leaflet.gridLayer.googleMutant({
  type: 'hybrid',
});

export const IGN_LAYER = Leaflet.tileLayer(
  'https://data.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&STYLE=normal&FORMAT={format}&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
  {
    attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
    minZoom: 0,
    maxZoom: IGN_MAX_ZOOM,
    format: 'image/png',
  },
);
