import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { equipmentSession, parcel, parcelSession, transformEntityWithCamelCase } from '../api/schema';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';
import { normalize, preNormalize } from '../utils';
import { receiveParcel } from './parcels';

const receiveEquipmentSession = (payload) => ({
  type: Actions.EQUIPMENT_SESSION_SUCCESS,
  payload,
});

const receiveEquipmentSessionPoints = (payload) => ({
  type: Actions.EQUIPMENT_SESSION_POINTS_SUCCESS,
  payload,
});

const fetchParcelOfEs = (es, dispatch) => {
  const parcelIDs = [];
  const parcelPromises = [];
  (es.parcel_sessions || []).forEach((ps) => {
    if (parcelIDs.indexOf(ps.parcel_id) === -1) {
      parcelIDs.push(ps.parcel_id);
      parcelPromises.push(Api.getParcel(ps.parcel_id));
    }
  });
  return Promise.all(parcelPromises).then(
    (results) => {
      (results || []).forEach((result) => dispatch(receiveParcel(normalize([result], [parcel]))));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const fetchEquipmentSession = (id) => (dispatch) =>
  Api.getEquipmentSession(id).then(
    (response) => {
      dispatch(receiveEquipmentSession(normalize(response, equipmentSession)));
      fetchParcelOfEs(response, dispatch);
    },
    (e) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.ERROR,
          duration: 5000,
          message: I18n.t('EquipmentSession.session_not_exists'),
          action: 'OK',
        }),
      );
      return Promise.reject(e);
    },
  );

export const fetchEquipmentSessionPoints = (id) => (dispatch) =>
  Api.getEquipmentSessionPoints(id).then((points) => {
    dispatch(
      receiveEquipmentSessionPoints({
        id,
        points,
      }),
    );
  });

const receiveParcelSession = (payload) => ({
  type: Actions.RECEIVE_PARCEL_SESSION_SUCCESS,
  payload,
});

export const fetchParcelSessionById = (id) => (dispatch) =>
  Api.getParcelSession(id).then(
    (response) => {
      dispatch(
        receiveParcelSession({
          id,
          data: normalize([response], [parcelSession]),
        }),
      );
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const requestParcelTracks = () => ({
  type: Actions.REQUEST_PARCEL_SESSIONS_BY_IDS,
});

const receiveParcelTracks = (payload) => ({
  type: Actions.RECEIVE_PARCEL_SESSIONS_BY_IDS,
  payload,
});

export const fetchParcelTracks = (payload) => (dispatch) => {
  dispatch(requestParcelTracks());
  const { start, end, equipmentId, parcelId, status, deviceSessionId, clusterId } = payload;
  const params = new URLSearchParams();
  if (start) {
    params.set('fromDate', start);
  }
  if (end) {
    params.set('toDate', end);
  }
  if (equipmentId) {
    params.set('equipmentID', equipmentId);
  }
  if (parcelId) {
    params.set('parcelID', parcelId);
  }
  if (status?.length) {
    status.forEach((s) => params.append('status', s));
  }
  if (deviceSessionId) {
    params.set('device_session_id', deviceSessionId);
  }
  if (clusterId) {
    params.set('clusterID', clusterId);
  }
  Api.getParcelsTracks(params).then(
    (response) => {
      dispatch(receiveParcelTracks(response));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestEquipmentTracks = () => ({
  type: Actions.REQUEST_EQUIPMENT_SESSIONS_BY_IDS,
});

const receiveEquipmentTracks = (payload) => ({
  type: Actions.RECEIVE_EQUIPMENT_SESSIONS_BY_IDS,
  payload,
});

export const fetchEquipmentTracks = (payload) => (dispatch) => {
  dispatch(requestEquipmentTracks());
  const { start, end, equipmentId, parcelId, status } = payload;
  let params = `fromDate=${encodeURIComponent(start)}&toDate=${encodeURIComponent(end)}`;
  if (equipmentId) {
    params += `&equipmentID=${equipmentId}`;
  }
  if (parcelId) {
    params += `&parcelID=${parcelId}`;
  }
  if (status?.length) {
    status.forEach((s) => (params += `&status=${s}`));
  }
  return Api.getEquipmentsTracks(params).then(
    (response) => {
      dispatch(receiveEquipmentTracks(response));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const updateClusterIdForSession = (sessionId, clusterId) => (dispatch) =>
  Api.updateClusterIdForSession(sessionId, clusterId).then(
    (result) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: I18n.t('Session.updated_success'),
          action: 'OK',
        }),
      );
      fetchParcelOfEs(result, dispatch);
      return dispatch(receiveEquipmentSession(normalize(result, equipmentSession)));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const requestSharedSession = () => ({
  type: Actions.REQUEST_SHARED_SESSION,
});

const failSharedSession = (error) => ({
  type: Actions.FAIL_SHARED_SESSION,
  error,
});

const receiveSharedSession = (payload) => ({
  type: Actions.RECEIVE_SHARED_SESSION,
  payload,
});

export const getSharedSession = (id) => (dispatch) => {
  dispatch(requestSharedSession());
  Api.getSharedSession(id).then(
    (response) => dispatch(receiveSharedSession(preNormalize(transformEntityWithCamelCase(response)))),
    (error) => {
      if (error?.errors) {
        // means 404
        return dispatch(failSharedSession(error));
      } else {
        return errorHasOccurred(error, dispatch);
      }
    },
  );
};

export const updateSharedSession = (payload) => ({
  type: Actions.UPDATED_SHARED_SESSION,
  payload,
});

export const addParcelsToDeviceSession = (deviceSessionID, parcelIDs, disableResetSession) => (dispatch) => {
  return Api.addParcelsToDeviceSession(deviceSessionID, parcelIDs, disableResetSession).then(
    () => {
      return dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.SUCCESS,
          duration: 3000,
          message: I18n.t('EditDeviceSessionParcels.success'),
          action: SNACK_CONSTANTS.ACTION.OK,
        }),
      );
    },
    (e) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.ERROR,
          duration: 5000,
          message: I18n.t('EquipmentSession.error_to_add_parcels'),
          action: 'OK',
        }),
      );
      return Promise.reject(e);
    },
  );
};

export const ignoreParcelsToDeviceSession =
  (deviceSessionID, parcelIDs, disableResetSession = false) =>
  (dispatch) => {
    return Api.ignoreParcelsToDeviceSession(deviceSessionID, parcelIDs, disableResetSession).then(
      () => {
        return dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.SUCCESS,
            duration: 3000,
            message: I18n.t('EditDeviceSessionParcels.success'),
            action: SNACK_CONSTANTS.ACTION.OK,
          }),
        );
      },
      (e) => {
        dispatch(
          snackAdded({
            type: SNACK_CONSTANTS.TYPE.ERROR,
            duration: 5000,
            message: I18n.t('EquipmentSession.error_to_ignore_parcels'),
            action: 'OK',
          }),
        );
        return Promise.reject(e);
      },
    );
  };

const receiveForcedOrIgnoredParcelsOfDeviceSession = (deviceSessionId, forcedOrIgnoredParcels) => ({
  type: Actions.GET_FORCED_OR_IGNORED_PARCELS_OF_DEVICE_SESSION_SUCCESS,
  payload: {
    deviceSessionId,
    forcedOrIgnoredParcels,
  },
});
export const getForcedOrIgnoredParcelsOfDeviceSession = (deviceSessionId) => (dispatch) => {
  return Api.getForcedOrIgnoredParcelsOfDeviceSession(deviceSessionId).then(
    (response) =>
      dispatch(
        receiveForcedOrIgnoredParcelsOfDeviceSession(
          deviceSessionId,
          Object.values(preNormalize(transformEntityWithCamelCase(response))),
        ),
      ),
    (error) => {
      return errorHasOccurred(error, dispatch);
    },
  );
};

export const unFreezeEquipmentSession = (sessionID) => () => {
  return Api.unfreezeSession(sessionID);
};

const updateEquipmentSessionStatus = (sessionID, status) => ({
  type: Actions.UPDATE_EQUIPMENT_SESSION_STATUS,
  payload: {
    sessionID,
    status,
  },
});

export const freezeEquipmentSession = (sessionID, status) => (dispatch) => {
  return Api.freezeSession(sessionID, status).then(
    () => dispatch(updateEquipmentSessionStatus(sessionID, status)),
    (e) => errorHasOccurred(e, dispatch),
  );
};
