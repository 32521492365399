import { WorksitePopup } from '@karnott/map';

export function WorksiteTooltip({ worksiteProps, labels }) {
  const { Icons, worksiteType, fromDate, toDate, isInProgress, lastUpdate, actions } = worksiteProps;

  return (
    <WorksitePopup
      Icons={Icons}
      worksiteType={worksiteType}
      fromDate={fromDate}
      toDate={toDate}
      isInProgress={isInProgress}
      lastUpdate={lastUpdate}
      actions={actions}
      labels={labels}
    />
  );
}
