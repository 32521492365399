import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { COLOR_ID } from '../constants/parcelsConstants';
import { getEquipmentsStoredById } from '../selectors/equipments';
import { getActiveTrackColoration } from '../selectors/parcelColors';
import { trackHasClusterColor, trackHasEquipmentColor } from '../utils/tracks';

export function useTrackColoration() {
  const activeColoration = useSelector(getActiveTrackColoration);
  const equipmentsById = useSelector(getEquipmentsStoredById);

  return useCallback(
    (trackProps) => {
      if (!activeColoration.title) {
        return;
      }
      const equipmentId = trackProps.equipment_id || trackProps.equipment_instance_id;
      const clusterId = trackProps.cluster_id || equipmentsById[equipmentId]?.clusterId;

      for (const item of activeColoration?.items || []) {
        if (
          activeColoration.id === COLOR_ID.CLUSTERS
            ? trackHasClusterColor(clusterId, item)
            : activeColoration.id === COLOR_ID.EQUIPMENT
              ? trackHasEquipmentColor(equipmentId, item)
              : false
        ) {
          return item.color;
        }
      }
    },
    [activeColoration, equipmentsById],
  );
}
