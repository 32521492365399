import { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { pixelSpacing } from '@karnott/theme';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { snackDismissed } from '../slices/snacks';
import { family, spacing, ui } from '../ui/theme';

function SnackBar({ dispatchDismissSnack, snacks }) {
  const wrapper = useRef(null);
  const [activeSnack, setActiveSnack] = useState({});
  const [snackbarIsActive, setSnackbarIsActive] = useState(false);
  const [, setCloseTimeout] = useState({});

  const handleWillDismiss = useCallback(
    (snack, cancelTimeout = true) => {
      setSnackbarIsActive(false);
      if (cancelTimeout) {
        setCloseTimeout((timeouts) => {
          const t = timeouts[snack.id];
          if (t) {
            clearTimeout(t);
            delete timeouts[snack.id];
          }
          return timeouts;
        });
      }
      dispatchDismissSnack(snack.id);
    },
    [dispatchDismissSnack],
  );

  useEffect(() => {
    const snackID = snacks.queue[0];
    const snack = snacks.byId[snackID];
    if (!isEmpty(snack)) {
      setSnackbarIsActive(true);
      setActiveSnack(snack);
      if (snack.duration) {
        setCloseTimeout((timeouts) => {
          timeouts[snack.id] = setTimeout(() => {
            handleWillDismiss(snack, false);
          }, snack.duration);
          return timeouts;
        });
      }
    }
  }, [handleWillDismiss, snacks, setCloseTimeout]);

  const [currentWidth, setCurrentWidth] = useState(0);
  useEffect(() => {
    setCurrentWidth(wrapper.current?.offsetWidth / 2);
  }, [activeSnack]);

  return (
    <Wrapper
      shown={snackbarIsActive}
      id="snackbar"
      ref={wrapper}
      offset={currentWidth}
      error={activeSnack.type === SNACK_CONSTANTS.TYPE.ERROR}
    >
      <Message>{activeSnack.message}</Message>
      <Action disabled={!snackbarIsActive} onClick={() => handleWillDismiss(activeSnack)} type={activeSnack.type}>
        {activeSnack.action || 'ok'}
      </Action>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  transform: ${(props) => (props.shown ? 'translateY(0);' : 'translateY(100%);')};
  left: ${({ offset }) => `calc(50% - ${offset}px)`};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-width: 20em;
  max-width: 100%;
  background-color: ${({ error }) => (error ? colors('red') : colors('green'))};
  color: #eef6f6;
  transition: transform 200ms ease-in-out;
  z-index: 2147483648; /* Maximum possible */
  padding: ${spacing}px;
  border: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  font-family: ${family('ui')};
`;

const Message = styled.p`
  margin: 0;
  flex: 1;
`;

const Action = styled.button`
  border: 0;
  border-radius: ${pixelSpacing('xSmall')};
  white-space: nowrap;
  text-transform: uppercase;
  margin-left: ${pixelSpacing('large')};
  font-weight: 600;
  outline: none;
  color: ${(props) => (props.type ? ui(props.type) : colors('orange'))};
  background-color: ${colors('white')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${pixelSpacing('small')};
  cursor: pointer;
`;

const mapStateToProps = (state) => ({
  snacks: state.snacks,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchDismissSnack: (id) => dispatch(snackDismissed(id)),
});

const ConnectedSnackBar = connect(mapStateToProps, mapDispatchToProps)(SnackBar);
export default ConnectedSnackBar;
