import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const TaskCreateIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 18 21.582"
    >
      <defs>
        <clipPath>
          <rect width="18" height="21.582" fill="none" stroke={color} strokeWidth="1.25" />
        </clipPath>
      </defs>
      <g transform="translate(-133.5 -513.183)">
        <g transform="translate(133.5 513.183)">
          <g clipPath="url(#clipPath)">
            <path
              d="M3.954,2.139h-2A1.547,1.547,0,0,0,.5,3.718V19.5a1.547,1.547,0,0,0,1.457,1.579H16.043A1.547,1.547,0,0,0,17.5,19.5V3.718a1.547,1.547,0,0,0-1.457-1.579H13.925"
              transform="translate(0 0)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.25"
            />
            <path
              d="M13.8,2.139A1.685,1.685,0,0,0,12.1.5H5.782a1.725,1.725,0,0,0-1.7,1.639V4.082H13.8Z"
              transform="translate(0 0)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.25"
            />
          </g>
        </g>
        <g transform="translate(139.5 521.974)">
          <g>
            <line y2="6" transform="translate(3)" fill="none" stroke={color} strokeLinecap="round" strokeWidth="1.25" />
            <line
              x2="6"
              transform="translate(0 3)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeWidth="1.25"
            />
          </g>
        </g>
      </g>
    </svg>
  </CircledIcon>
);
