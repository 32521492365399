import { createSelector } from '@reduxjs/toolkit';
import { COLOR_CATEGORIES } from '../constants/parcelsConstants';
import { colorLabelBuilderById } from '../utils/parcels';
import { getUser } from './index';

const getColors = (state) => state.parcelColors.colors;

export const getEffectiveColors = createSelector([getColors, getUser], (colors, user) =>
  colors.map((effectiveColor) => enrichColoration(effectiveColor, user.unit_area)),
);

const getActiveColoration = (colors, user, category) => {
  const checkedColor = colors.find((c) => c.checked[category]);
  return enrichColoration(checkedColor || {}, user.unit_area);
};

export const getActiveParcelColoration = createSelector([getColors, getUser], (colors, user) =>
  getActiveColoration(colors, user, COLOR_CATEGORIES.PARCELS),
);

export const getActiveTrackColoration = createSelector([getColors, getUser], (colors, user) =>
  getActiveColoration(colors, user, COLOR_CATEGORIES.TRACKS),
);

function enrichColoration(coloration, unit_area) {
  const items = coloration.items || [];
  return {
    ...coloration,
    items: [...items]
      .filter((item) => coloration.catalog[item.id] || item.id === 0)
      .map((item) => {
        const itemInCatalog = coloration.catalog[item.id] || item;
        const color = itemInCatalog.color || '';
        return {
          ...item,
          color: item.value || (color.startsWith('#') ? color : `#${color}`),
          label: item.label || colorLabelBuilderById[coloration.id]?.(itemInCatalog, unit_area),
        };
      })
      .filter(Boolean),
  };
}
