import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { colors } from '@karnott/colors';
import { DriverIcon } from '@karnott/icons';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { UIContext } from '../../../../contexts/ui';
import { useDrawMarkerOnMap } from '../../effects';
import { openDriverTooltip } from './events';

export function useDrawDriverOnMap(map, driver, events) {
  const { focusedDriverId, hideDriverTooltip } = useContext(UIContext);
  const history = useHistory();
  const focused = useMemo(() => {
    return focusedDriverId === driver.id;
  }, [focusedDriverId, driver.id]);

  const { location, Icon, iconColor, backgroundColor } = useMemo(() => {
    return {
      location: { latitude: driver.latitude, longitude: driver.longitude },
      Icon: DriverIcon,
      iconColor: colors('grey', 100),
      backgroundColor: focused
        ? colors('black', 'dark')
        : driver.status === 'IN_USE'
          ? colors('green')
          : colors('grey', 'light'),
    };
  }, [focused, driver.latitude, driver.longitude, driver.status]);

  const marker = useDrawMarkerOnMap(map, driver, location, Icon, iconColor, backgroundColor, events, false);
  const openedTooltipLayer = useRef(null);
  const labels = useDriverTooltipLabels();
  const driverProps = driverTooltipData(driver, history);

  useEffect(() => {
    const { layerGroup, iconMarker, backgroundMarker } = marker;
    if (!focusedDriverId || focusedDriverId !== driver.id || !layerGroup || !iconMarker || !backgroundMarker)
      return () => {};
    openedTooltipLayer.current = marker;
    !hideDriverTooltip && openDriverTooltip(driverProps, iconMarker, labels);
    iconMarker.setZIndexOffset(10000);
    backgroundMarker.setZIndexOffset(10000);

    return () => {
      if (openedTooltipLayer.current) {
        openedTooltipLayer.current.iconMarker.closeTooltip();
        iconMarker.setZIndexOffset(0);
        backgroundMarker.setZIndexOffset(0);
        openedTooltipLayer.current = null;
      }
    };
  }, [focusedDriverId, labels, marker, openedTooltipLayer, driver, driverProps, Icon, hideDriverTooltip]);
}

export function useDriverCoords(drivers = []) {
  return useMemo(() => {
    return (
      drivers.length &&
      drivers
        .filter((e) => e && e.latitude && e.longitude)
        .reduce((value, driver) => {
          return value.concat([[driver.latitude, driver.longitude]]);
        }, [])
    );
  }, [drivers]);
}

export function useDriversSession(drivers, hideActiveSession) {
  return useMemo(() => {
    const driversWithActiveSession = drivers && drivers.filter((e) => e && e.activeSession);
    if (hideActiveSession || !driversWithActiveSession || !driversWithActiveSession.length) return null;
    const tracks = {
      type: 'FeatureCollection',
    };
    let features = [];
    driversWithActiveSession
      .filter((driver) => driver.activeSession.track)
      .forEach((driver) => {
        let activeSession = {};
        activeSession.geometry = driver.activeSession.track;
        activeSession.properties = {
          device_session_id: driver.activeSession.deviceSessionId,
          fromDate: driver.activeSession.fromDate,
          driver_name: driver.fullname,
        };
        activeSession.type = 'Feature';
        features.push(activeSession);
      });
    tracks.features = features;
    return tracks;
  }, [drivers, hideActiveSession]);
}

export function useDriverTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      last_update: t('Dashboard.last_actualization'),
      active: t('Commons.active'),
      inactive: t('Commons.inactive'),
      see: t('Commons.see'),
    }),
    [t],
  );
}

export function driverTooltipData(driver, history) {
  return {
    name: driver.fullname,
    isActive: driver.status === 'IN_USE',
    beaconUUID: driver.beacon?.readableUuid,
    clusterName: driver.cluster.name,
    lastDate: moment(driver.locationDate).format('LLL'),
    id: driver.id,
    history,
  };
}
