import { ComponentProps, ReactNode, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { SaveIcon } from '@karnott/icons';
import { fontFamily, msDuration, pixelSize, pixelSpacing } from '@karnott/theme';
import { Tooltip, TooltipMessage } from '@karnott/tooltip';

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${pixelSpacing('xSmall')};
`;

const CheckboxWrapper = styled.label<{
  checked: boolean;
  $color?: string;
  checkedColor?: string;
}>`
  display: flex;
  align-items: center;
  gap: ${pixelSpacing('small')};
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.$color || (props.checked ? colors('black', 600) : colors('grey'))};
  transition: all ${msDuration('short')} ease-in-out;
  font-family: ${fontFamily()};
  font-size: ${pixelSize()};

  [type='checkbox'] {
    display: none;
  }

  :hover {
    color: ${(props) => props.$color || colors('black', 600)};
  }

  :hover > div:first-of-type {
    box-shadow: inset 0 0 0 1px ${({ checkedColor }) => checkedColor || colors('green')};
  }
`;

const CheckboxIconWrapper = styled.div<{
  checked: boolean;
  checkedColor?: string;
  defaultColor?: string;
}>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px
    ${({ checked, defaultColor }) => (checked ? 'transparent' : defaultColor || colors('grey'))};
  background-color: ${({ checked, checkedColor }) => (checked ? checkedColor || colors('green') : colors('white'))};
  transition: all ${msDuration('regular')} ease-in-out;
  position: relative;

  ::after {
    // extended clickable area
    content: '';
    width: 28px;
    height: 28px;
    position: absolute;
    left: -6px;
  }
`;

const CheckboxLabel = styled.span<{
  hide: boolean;
}>`
  display: ${({ hide }) => (hide ? 'none' : 'inline')};
`;

const CheckboxTooltip = styled.div`
  margin-left: ${pixelSpacing('small')};
`;

type Orientation = 'top' | 'right' | 'bottom' | 'left';
type TooltipProps = ComponentProps<typeof Tooltip>;

type CheckboxProps = {
  /** Whether the checkbox is in a checked state */
  checked: boolean;
  /** The color of the checked state */
  checkedColor?: string;
  /** The color of the unchecked state */
  defaultColor?: string;
  /** The color of the text */
  color?: string;
  /** The label of the checkbox */
  label?: ReactNode;
  /** The id of the checkbox. Defaults to the label */
  id?: string;
  /** Whether the label should be hidden */
  hideLabel?: boolean;
  /** The callback when the checkbox is clicked, to update its state */
  onChange: (id: string) => void;
  /** The tooltip definition to explain the option */
  tooltip?: TooltipProps;
  /** The orientation of the tooltip showing the complete label */
  hoverTextOrientation?: Orientation;
};

/** A checkbox to represent a selected or unselected option */
function Checkbox({
  checked,
  checkedColor,
  defaultColor,
  color,
  label,
  id,
  hideLabel = false,
  onChange,
  tooltip,
  hoverTextOrientation = 'top',
}: CheckboxProps) {
  const [hovered, setHovered] = useState(false);
  const pRef = useRef<HTMLDivElement>(null);
  const onOver = useCallback(
    (state: boolean) => {
      if (pRef.current && pRef.current.scrollWidth > pRef.current.offsetWidth) {
        setHovered(state);
      }
    },
    [setHovered],
  );

  const checkboxId = id || (typeof label === 'string' ? label : undefined);

  return (
    <CheckboxWrapper
      onClick={(e) => e.stopPropagation()}
      onFocus={() => onOver(true)}
      onMouseOver={() => onOver(true)}
      onMouseLeave={() => onOver(false)}
      htmlFor={checkboxId}
      checkedColor={checkedColor}
      checked={checked}
      $color={color}
    >
      <input type="checkbox" id={checkboxId} checked={checked} onChange={() => onChange(checkboxId || '')} />

      <CheckboxIconWrapper checked={checked} checkedColor={checkedColor} defaultColor={defaultColor}>
        <SaveIcon size={15} circled backgroundColor="transparent" color={colors('white')} />
      </CheckboxIconWrapper>

      {hideLabel || !label ? null : (
        <div ref={pRef}>
          {hovered && typeof label === 'string' && (
            <TooltipMessage orientation={hoverTextOrientation} message={label} />
          )}
          <CheckboxLabel hide={hideLabel}>{label}</CheckboxLabel>
        </div>
      )}

      {tooltip ? (
        <CheckboxTooltip>
          <Tooltip {...tooltip} />
        </CheckboxTooltip>
      ) : null}
    </CheckboxWrapper>
  );
}

type CheckboxesProps = {
  /** The color of the checked state */
  checkedColor?: string;
  /** The color of the unchecked state */
  defaultColor?: string;
  /** The labels of the checkboxes */
  items: string[];
  /** The checked labels */
  selection: string[];
  /** Callback when a checkbox is clicked, to update the state */
  onChange: (id: string) => void;
  /** One tooltip definition per checkbox */
  tooltips?: TooltipProps[];
};

/** A group of checkboxes */
function Checkboxes({ checkedColor, defaultColor, items, selection, onChange, tooltips = [] }: CheckboxesProps) {
  return (
    <CheckboxesContainer>
      {items.map((item, index) => {
        const tooltip = tooltips[index];
        return (
          <Checkbox
            key={`${item}-${index}`}
            checked={selection.includes(item)}
            label={item}
            onChange={onChange}
            tooltip={tooltip}
            checkedColor={checkedColor}
            defaultColor={defaultColor}
          />
        );
      })}
    </CheckboxesContainer>
  );
}

export { Checkbox, Checkboxes };
