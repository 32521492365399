import { I18n } from '../i18n';

const t = I18n.t;

export const isRequired = (value) => (value ? undefined : t('Error.required'));

export const isValidationEmail = (value) =>
  value && !/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i.test(value) ? t('Error.invalid_email') : undefined;

export const isNumber = (value) =>
  value && !/^[+]?\d+([.]\d+)?$/i.test(value) ? t('Error.invalid_number') : undefined;
