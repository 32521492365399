import map from 'lodash-es/map';
import omit from 'lodash-es/omit';
import transform from 'lodash-es/transform';
import uniq from 'lodash-es/uniq';
import values from 'lodash-es/values';
import zipObject from 'lodash-es/zipObject';
import moment from 'moment-timezone';
import { Actions } from '../actions';

const initialState = {
  isFetching: false,
  devicesIds: [],
  deviceById: {},
  deviceModelById: {},
  equipmentDevicesIds: [],
  equipmentDeviceById: {},
};

const devices = (state = initialState, action) => {
  const { type, payload } = action;
  let device;

  switch (type) {
    case Actions.DEVICES_REQUEST:
      return {
        ...state,
        isFetching: payload.refresh,
      };
    case Actions.EQUIPMENT_HAS_DEVICE_EDIT_SUCCESS: {
      // need to remove equipment_has_device link to the device Id (before refresh)
      // because after edit one equipment_has_device we can have many merge/delete
      const { deviceId } = payload;
      const equipmentDeviceByIdToSave = {};
      const equipmentDevicesIdsToSave = [];
      for (const id in state.equipmentDeviceById) {
        const ehd = state.equipmentDeviceById[id];
        if (ehd.deviceInstanceId !== deviceId) {
          equipmentDeviceByIdToSave[id] = ehd;
          equipmentDevicesIdsToSave.push(id);
        }
      }
      return {
        ...state,
        equipmentDevicesIds: equipmentDevicesIdsToSave,
        equipmentDeviceById: equipmentDeviceByIdToSave,
      };
    }
    case Actions.DEVICE_HISTORY_SUCCESS: {
      const deviceByID = { ...state.deviceById };
      const equipmentHasDeviceSuccess = payload.entities.equipmentHasDevice || {};
      (Object.values(equipmentHasDeviceSuccess) || []).forEach((ehd) => {
        const device = deviceByID[ehd.deviceInstanceId];
        const now = moment();
        const isCurrent =
          moment(ehd.fromDate).isSameOrBefore(now) &&
          (ehd.toDate === undefined || ehd.toDate === null || moment(ehd.toDate).isAfter(now));
        if (device && isCurrent && device.currentEquipmentInstanceId !== ehd.equipmentInstanceId) {
          device.currentEquipmentInstanceId = ehd.equipmentInstanceId;
        }
      });
      return {
        ...state,
        equipmentDevicesIds: uniq([...payload.result, ...state.equipmentDevicesIds]),
        equipmentDeviceById: {
          ...state.equipmentDeviceById,
          ...zipObject(
            payload.result,
            map(payload.result, (id) => payload.entities.equipmentHasDevice[id]),
          ),
        },
        deviceById: deviceByID,
      };
    }
    case Actions.DEVICES_SUCCESS: {
      const { deviceModel } = payload.entities;
      return {
        ...state,
        isFetching: false,
        devicesIds: uniq([...state.devicesIds, ...payload.result]),
        deviceById: { ...state.deviceById, ...payload.entities.deviceInstance },
        deviceModelById: {
          ...state.deviceModelById,
          ...values(deviceModel).reduce(
            (obj, model) => ({
              ...obj,
              [model.equipmentType]: omit(model, ['photo']),
            }),
            {},
          ),
        },
      };
    }
    case Actions.DEVICE_SUCCESS:
      return {
        ...state,
        deviceById: { ...state.deviceById, ...payload.data.entities.deviceInstance },
      };
    case Actions.EQUIPMENTS_SUCCESS:
      if (payload.entities?.equipmentHasDevice) {
        return {
          ...state,
          equipmentDeviceById: payload.entities.equipmentHasDevice,
          equipmentDevicesIds: Object.keys(payload.entities.equipmentHasDevice).map(Number),
        };
      }
      return state;

    case Actions.DEVICE_EDIT_SUCCESS:
      device = payload.entities.deviceInstance[payload.result];
      return {
        ...state,
        deviceById: {
          ...state.deviceById,
          [payload.result]: {
            ...state.deviceById[payload.result],
            ...device,
          },
        },
      };
    case Actions.DEVICE_ATTACH_EQUIPMENT_SUCCESS: {
      const { equipmentHasDevice } = payload.entities;
      const equipmentHasDeviceArray = Object.values(equipmentHasDevice);
      // Need to update currentEquipmentInstanceId
      const deviceById = transform(
        state.deviceById,
        (result, value, key) => {
          let { currentEquipmentInstanceId } = value;
          const now = moment();
          for (const i in equipmentHasDeviceArray) {
            const ehd = equipmentHasDeviceArray[i];
            const isCurrent =
              moment(ehd.fromDate).isSameOrBefore(now) &&
              (ehd.toDate === undefined || ehd.toDate === null || moment(ehd.toDate).isAfter(now));
            if (isCurrent) {
              if (ehd.equipmentInstanceId === value.currentEquipmentInstanceId) {
                currentEquipmentInstanceId = 0;
                break;
              }
              if (ehd.deviceInstanceId === value.id) {
                currentEquipmentInstanceId = ehd.equipmentInstanceId;
                break;
              }
            }
            if (ehd.deviceInstanceId === value.id) {
              currentEquipmentInstanceId = 0;
            }
          }
          value.currentEquipmentInstanceId = currentEquipmentInstanceId;
          return (result[key] = value);
        },
        {},
      );
      return {
        ...state,
        deviceById,
        equipmentDevicesIds: payload.result,
        equipmentDeviceById: {
          ...zipObject(
            payload.result,
            map(payload.result, (id) => equipmentHasDevice[id]),
          ),
        },
      };
    }
    case Actions.EQUIPMENT_SESSIONS_SUCCESS:
    case Actions.EQUIPMENT_SESSION_SUCCESS: {
      const theDeviceModel = payload?.entities?.deviceModel;
      return {
        ...state,
        deviceModelById: {
          ...state.deviceModelById,
          ...values(theDeviceModel).reduce(
            (obj, model) => ({
              ...obj,
              [model.id]: omit(model, ['photo']),
            }),
            {},
          ),
        },
      };
    }
    case Actions.EQUIPMENT_HAS_DEVICE_DELETE_SUCCESS: {
      let { equipmentDevicesIds, equipmentDeviceById } = state;
      equipmentDevicesIds = equipmentDevicesIds.filter((id) => id !== payload);
      const { fromDate, toDate, deviceInstanceId } = equipmentDeviceById[payload];
      delete equipmentDeviceById[payload];

      // Need to update currentEquipmentInstanceId
      const now = moment();
      const devices = { ...state.deviceById };
      const isCurrent =
        moment(fromDate).isSameOrBefore(now) &&
        (toDate === undefined || toDate === null || moment(toDate).isAfter(now));
      if (isCurrent) {
        const device = state.deviceById[deviceInstanceId];
        device.currentEquipmentInstanceId = null;
        devices[deviceInstanceId] = device;
      }
      return {
        ...state,
        deviceById: devices,
        equipmentDevicesIds,
        equipmentDeviceById,
      };
    }
    case Actions.DEVICE_LINK_EXISTING_TAG: {
      const device = state.deviceById[payload.deviceId];
      return {
        ...state,
        deviceById: {
          ...state.deviceById,
          [payload.deviceId]: { ...device, tags: [...(device.tags || []), payload.tag] },
        },
      };
    }
    case Actions.DEVICE_UNLINK_TAG: {
      const device = state.deviceById[payload.deviceId];
      return {
        ...state,
        deviceById: {
          ...state.deviceById,
          [payload.deviceId]: {
            ...device,
            tags: (device.tags || []).filter((tag) => tag.id !== payload.tagId),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default devices;
