import { createAppSlice } from '.';
import { getEquipmentSessionUnitLocations, getParcelSessionUnitLocations } from '../api/api';
import { EquipmentModel } from '../constants/equipmentConstants';
import { EquipmentSessionId, ParcelSessionId } from './sessions';
import { alerting } from './snacks';

type UnitLocation = {
  equipment_session_id: EquipmentSessionId;
  equipment_model: EquipmentModel;
  location: string;
  location_latitude: number;
  location_longitude: number;
  occurred_at: string;
};

type State = {
  isFetching: boolean;
  unitLocationsByEquipmentSessionId: Record<EquipmentSessionId, UnitLocation[] | null>;
  unitLocationsByParcelSessionId: Record<ParcelSessionId, UnitLocation[] | null>;
};

const initialState: State = {
  isFetching: false,
  unitLocationsByEquipmentSessionId: {},
  unitLocationsByParcelSessionId: {},
};

function setFetching(state: State) {
  state.isFetching = true;
}

function setFetchingDone(state: State) {
  state.isFetching = false;
}

const unitLocationsSlice = createAppSlice({
  name: 'unitLocations',
  initialState,
  reducers: (create) => ({
    fetchEquipmentSessionUnitLocations: create.asyncThunk(
      async (equipmentSessionId: EquipmentSessionId) => {
        const unitLocations = (await alerting(() => getEquipmentSessionUnitLocations(equipmentSessionId))) as
          | UnitLocation[]
          | null;
        return unitLocations;
      },
      {
        pending: setFetching,
        fulfilled: (state, { payload, meta }) => {
          state.unitLocationsByEquipmentSessionId[meta.arg] = payload;
        },
        settled: setFetchingDone,
      },
    ),
    fetchParcelSessionUnitLocations: create.asyncThunk(
      async (parcelSessionId: ParcelSessionId) => {
        const unitLocations = (await alerting(() => getParcelSessionUnitLocations(parcelSessionId))) as
          | UnitLocation[]
          | null;
        return unitLocations;
      },
      {
        pending: setFetching,
        fulfilled: (state, { payload, meta }) => {
          state.unitLocationsByParcelSessionId[meta.arg] = payload;
        },
        settled: setFetchingDone,
      },
    ),
  }),
});

export const { fetchEquipmentSessionUnitLocations, fetchParcelSessionUnitLocations } = unitLocationsSlice.actions;

export default unitLocationsSlice.reducer;
