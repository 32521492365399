import { ClusterId } from '../slices/clusters';

export const trackHasClusterColor = (clusterId: ClusterId, colorItem?: { id?: unknown }) => {
  return clusterId === colorItem?.id;
};

export const trackHasEquipmentColor = (equipmentId: string, colorItem?: { id?: unknown }) => {
  return equipmentId === colorItem?.id;
};

export const defaultTrackColor = '#B5E936';
