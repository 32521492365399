import { useContext } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { CentralModal } from '@karnott/modals';
import { pixelSpacing } from '@karnott/theme';
import { I18nContext } from '../contexts/I18nProvider';
import { InformationText } from './tasks/modals/shared/components';

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${pixelSpacing()};
`;

const LeaveIE = function ({ isOpen }) {
  const { t } = useContext(I18nContext);

  return (
    <CentralModal showModal={isOpen} title={t('LeaveIE.modal_title')}>
      <InformationText warning noMargin>
        {t('LeaveIE.warning_label')}
      </InformationText>
      <Buttons>
        <Button href="https://www.google.fr/chrome/" newTab title={t('LeaveIE.go_chrome')} />
        <Button href="https://www.mozilla.org/fr/firefox/" newTab title={t('LeaveIE.go_ff')} />
      </Buttons>
    </CentralModal>
  );
};

export default LeaveIE;
