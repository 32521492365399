import { GOOGLE_MUTANT_TILES } from '../../constants/mapConstants';
import Map from './Map';
import { MapProvider } from './contexts/map';

export default function IsolatedMap(props) {
  return (
    <MapProvider id={props.id} tiles={GOOGLE_MUTANT_TILES}>
      <Map {...props} />
    </MapProvider>
  );
}
