import { LOCATION_CHANGE } from 'redux-first-history';
import { Actions } from '../actions';
import { initialParcelsFilters } from '../constants/filtersConstants';
import { I18n } from '../i18n';
import { formatPageTitle } from '../utils';
import { localStorageKeys } from '../utils/storage';

const initialState = {
  fullScreenMap: false,
  fullScreenDashboardMap: false,
  showMapSessionReplay: false,
  mapMeta: {
    zoom: null,
    longitude: null,
    latitude: null,
  },
  searchFilter: '',
  selectedClusters: [],
  parcelSessionsTracksToggle: false,
  equipmentSessionsTracksToggle: false,
  mouseOnGoogleMapsMarker: false,
  mousePosOnMarkerOut: null,
  overSession: null,
  parcelsBoxesOffset: 0,
  equipmentsBoxesOffset: 0,
  equipmentPageOffset: {},
  parcelPageOffset: {},
  workedParcelsPageOffset: {},
  useIgnTiles: localStorage.getItem(localStorageKeys.ign) === 'true',
  selectedParcelTypes: initialParcelsFilters.parcelTypesCheckboxes.items
    .map((checkbox) => ({
      ...checkbox,
      i18nLabel: I18n.t(checkbox.label),
      id: checkbox.label,
    }))
    .filter((t) => t.id !== 'Filters.inactive_parcel'),
  selectedCrops: [],
  selectedTags: [],
  initialTags: [],
  hasFetchedInitialTags: false,
  selectedAreaRange: {},
  selectedParcelIds: [],
  hideIntercomMessenger: false,
};

const ui = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Actions.SELECT_CLUSTERS: {
      let newSelectedClusters = [...state.selectedClusters];
      payload.forEach((p) => {
        if (state.selectedClusters.includes(p)) {
          newSelectedClusters = newSelectedClusters.filter((id) => id !== p);
        } else {
          newSelectedClusters.push(p);
        }
      });
      return {
        ...state,
        selectedClusters: newSelectedClusters,
      };
    }
    case Actions.CLEAR_CLUSTERS:
      return {
        ...state,
        selectedClusters: [],
      };
    case Actions.UPDATE_SEARCH:
      return {
        ...state,
        searchFilter: payload,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        searchFilter: '',
      };
    case Actions.UPDATE_TITLE:
      return {
        ...state,
        title: formatPageTitle(payload),
      };
    case Actions.TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullScreenMap: !state.fullScreenMap,
      };
    case Actions.TOGGLE_FULLSCREEN_DASHBOARD:
      return {
        ...state,
        fullScreenDashboardMap: !state.fullScreenDashboardMap,
      };
    case Actions.SET_SHOW_SESSION_REPLAY:
      return {
        ...state,
        fullScreenMap: action.payload.show,
        showMapSessionReplay: action.payload.show,
      };
    case Actions.TOGGLE_PARCEL_SESSIONS_TRACKS:
      return {
        ...state,
        parcelSessionsTracksToggle: !state.parcelSessionsTracksToggle,
      };
    case Actions.TOGGLE_EQUIPMENT_SESSIONS_TRACKS:
      return {
        ...state,
        equipmentSessionsTracksToggle: !state.equipmentSessionsTracksToggle,
      };
    case Actions.OVER_SESSION:
      return {
        ...state,
        overSession: action.sessionId,
      };
    case Actions.SET_PARCELS_BOXES_OFFSET:
      return {
        ...state,
        parcelsBoxesOffset: payload,
      };
    case Actions.SET_EQUIPMENTS_BOXES_OFFSET:
      return {
        ...state,
        equipmentsBoxesOffset: payload,
      };
    case Actions.SET_EQUIPMENT_PAGE_OFFSET:
      return {
        ...state,
        equipmentPageOffset: { ...state.equipmentPageOffset, [payload.id]: payload.value },
      };
    case Actions.SET_PARCEL_PAGE_OFFSET:
      return {
        ...state,
        parcelPageOffset: { ...state.parcelPageOffset, [payload.id]: payload.value },
      };
    case Actions.SET_WORKED_PARCELS_PAGE_OFFSET:
      return {
        ...state,
        workedParcelsPageOffset: { ...state.workedParcelsPageOffset, [payload.id]: payload.value },
      };
    case Actions.TOGGLE_USE_IGN_TILES:
      localStorage.setItem(localStorageKeys.ign, `${!state.useIgnTiles}`);
      return {
        ...state,
        useIgnTiles: !state.useIgnTiles,
      };
    // need to use initialParcelsFilters.parcelTypesCheckboxes.items data (filtersConstans.js)
    case Actions.SET_PARCEL_TYPES:
      return {
        ...state,
        selectedParcelTypes: payload,
      };
    case Actions.TOGGLE_PARCEL_TYPES: {
      let newSelectedParcelTypes = [...state.selectedParcelTypes];
      const selectParcelTypesLabel = state.selectedParcelTypes.map((p) => p.label);
      payload.forEach((parcelType) => {
        if (selectParcelTypesLabel.includes(parcelType.label)) {
          newSelectedParcelTypes = newSelectedParcelTypes.filter(
            (selectedParcelType) => selectedParcelType.label !== parcelType.label,
          );
        } else {
          newSelectedParcelTypes.push(parcelType);
        }
      });
      return {
        ...state,
        selectedParcelTypes: newSelectedParcelTypes,
      };
    }
    case Actions.CLEAR_PARCEL_TYPES:
      return {
        ...state,
        selectedParcelTypes: initialState.selectedParcelTypes,
      };
    // need to use data generate in reducer/filters
    case Actions.SET_CROPS:
      return {
        ...state,
        selectedCrops: payload,
      };
    case Actions.TOGGLE_CROPS: {
      let newSelectedCrops = [...state.selectedCrops];
      const selectedCropsIDs = state.selectedCrops.map((c) => c.id);
      payload.forEach((crop) => {
        if (selectedCropsIDs.includes(crop.id)) {
          newSelectedCrops = newSelectedCrops.filter((selectedCrop) => selectedCrop.id !== crop.id);
        } else {
          newSelectedCrops.push(crop);
        }
      });
      return {
        ...state,
        selectedCrops: newSelectedCrops,
      };
    }
    case Actions.CLEAR_CROPS:
      return {
        ...state,
        selectedCrops: [],
      };
    case Actions.SET_TAGS:
      return {
        ...state,
        selectedTags: payload,
      };
    case Actions.SET_DEFAULT_PARCEL_TAGS:
      return {
        ...state,
        initialTags: payload,
        hasFetchedInitialTags: true,
      };
    case Actions.TOGGLE_TAGS: {
      let newSelectedTags = [...state.selectedTags];
      const selectedTagsIds = state.selectedTags.map((c) => c.id);
      payload.forEach((tag) => {
        if (selectedTagsIds.includes(tag.id)) {
          newSelectedTags = newSelectedTags.filter((selectedTag) => selectedTag.id !== tag.id);
        } else {
          newSelectedTags.push(tag);
        }
      });
      return {
        ...state,
        selectedTags: newSelectedTags,
      };
    }
    case Actions.RESET_TAGS:
      return {
        ...state,
        selectedTags: state.initialTags.map((id) => ({ id })),
      };
    case Actions.SET_AREA_RANGE:
      return {
        ...state,
        selectedAreaRange: payload,
      };
    case Actions.CLEAR_AREA_RANGE:
      return {
        ...state,
        selectedAreaRange: {},
      };
    case Actions.SET_PARCEL_IDS:
      return {
        ...state,
        selectedParcelIds: payload,
      };
    case Actions.TOGGLE_PARCEL_IDS: {
      let newSelectedParcelIds = [...state.selectedParcelIds];
      payload.forEach((parcelId) => {
        const index = newSelectedParcelIds.findIndex((id) => id === parcelId);
        if (index === -1) {
          newSelectedParcelIds.push(parcelId);
        } else {
          newSelectedParcelIds.splice(index, 1);
        }
      });
      return {
        ...state,
        selectedParcelIds: newSelectedParcelIds,
      };
    }
    case Actions.CLEAR_PARCEL_IDS:
      return {
        ...state,
        selectedParcelIds: [],
      };
    case Actions.HIDE_INTERCOM_MESSENGER:
      return {
        ...state,
        hideIntercomMessenger: payload,
      };
    case Actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default ui;
