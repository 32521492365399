import { colors as Colors } from '@karnott/colors';
import { color, ui } from './Colors';

const sizes = {
  xxxxl: 48,
  xxxl: 40,
  xxl: 30,
  xl: 22,
  l: 18,
  m: 16,
  s: 14,
  xs: 12,
  xxs: 10,
};

const zIndexValues = {
  ui: 1000,
  ui_map: 1100,
  ui_left_pane: 1200,
  ui_date_picker: 1300,
  ui_modal: 1500,
  ui_map_overlay: 1600,
  ui_max: 1999,
  modal_map: 2100,
  header: 2200,
  modal: 2201,
  modal_date_picker: 2400,
  modal_modal: 2500,
  modal_modal_datepicker: 2600,
  modal_overlay: 2999,
};

export const zIndex = (input, offset = 0) => zIndexValues[input] + offset || 1000;

export const spacing = sizes.m;

export const size = (input) => `${sizes[input] || sizes.m}px`;

const fontsFamilies = {
  system:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  ui: '"Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
  body: '"Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
};

export const family = (name) => fontsFamilies[name] || fontsFamilies.body;

export { color, ui };

export const chartColors = [
  Colors('green', 'dark'),
  Colors('green'),
  Colors('green', 'light'),
  Colors('orange', 'dark'),
  Colors('orange'),
  Colors('orange', 'light'),
];
