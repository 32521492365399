import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';

const t = I18n.t;

// PRODUCTS

const receiveFavoriteProduct = (product, clusterId) => ({
  type: Actions.GET_PHYTO_FAVORITE_PRODUCT,
  payload: { product, clusterId },
});

const receiveFavoriteProducts = (products, clusterId) => ({
  type: Actions.GET_PHYTO_FAVORITE_PRODUCTS,
  payload: { products, clusterId },
});

export const fetchFavoriteProducts = (clusterId) => (dispatch) => {
  return (
    !!clusterId &&
    Api.getPhytoFavoriteProducts(clusterId).then(
      (products) => dispatch(receiveFavoriteProducts(products, clusterId)),
      (e) => errorHasOccurred(e, dispatch),
    )
  );
};

export const fetchBasicProducts = (name, signal) => (dispatch) => {
  return Api.getPhytoBasicProducts(name, signal).then(
    (products) => Promise.resolve(products),
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const addProduct = (id, clusterId) => (dispatch) => {
  return Api.createPhytoFavoriteProduct(id, clusterId).then(
    (data) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: t('AdminAddProductModal.success_snack'),
          action: 'OK',
        }),
      );
      dispatch(receiveFavoriteProduct(data.product, clusterId));
      return data.product;
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const deletedProduct = (id, clusterId) => ({
  type: Actions.DELETE_PHYTO_PRODUCT,
  payload: { id, clusterId },
});

export const deleteProduct = (id, clusterId) => (dispatch) => {
  return Api.deletePhytoFavoriteProduct(id, clusterId).then(
    () => dispatch(deletedProduct(id, clusterId)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

// CUSTOM PRODUCTS

export const createCustomProduct = (product) => (dispatch) => {
  return Api.createCustomPhytoProduct(product).catch((e) => errorHasOccurred(e, dispatch));
};

export const updateCustomProduct = (product) => (dispatch) => {
  return Api.updateCustomPhytoProduct(product.id, product)
    .then(() => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: t('AdminCustomProductModal.edit_success_snack'),
          action: 'OK',
        }),
      );
      dispatch(fetchFavoriteProducts(product.cluster_id));
    })
    .catch((e) => errorHasOccurred(e, dispatch));
};

// MIXTURES

const receiveMixtures = (mixtures, clusterId) => ({
  type: Actions.GET_PHYTO_MIXTURES,
  payload: { mixtures, clusterId },
});

export const fetchMixtures = (clusterId) => (dispatch) => {
  if (!clusterId) return Promise.resolve();
  return Api.getPhytoMixtures(clusterId).then(
    (mixtures) => dispatch(receiveMixtures(mixtures, clusterId)),
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const fetchAllMixtures = () => (dispatch) => {
  return Api.getPhytoMixtures().then(
    (mixtures) => {
      const mixturesByClusterId = {};
      mixtures.forEach((mixture) => {
        mixturesByClusterId[mixture.cluster_id] ||= [];
        mixturesByClusterId[mixture.cluster_id]?.push(mixture);
      });
      for (const clusterId in mixturesByClusterId) {
        const mixtures = mixturesByClusterId[clusterId];
        dispatch(receiveMixtures(mixtures, clusterId));
      }
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const addMixture = (payload) => (dispatch) => {
  return Api.createPhytoMixture(payload).then(
    (mixture) => {
      dispatch(fetchMixtures(mixture.cluster_id));
      return mixture;
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const updateMixture = (mixtureId, payload) => (dispatch) => {
  let promises = [];
  if (payload.mixtureName) {
    promises.push(Api.updatePhytoMixture(mixtureId, { name: payload.mixtureName }));
  }
  if (payload.productsToDelete && Array.isArray(payload.productsToDelete)) {
    payload.productsToDelete.filter(Boolean).forEach((productUsageId) => {
      promises.push(Api.deletePhytoMixtureProduct(mixtureId, productUsageId));
    });
  }
  if (payload.products) {
    payload.products.forEach((product) => {
      if (product.toUpdate) {
        promises.push(Api.updatePhytoMixtureProduct(mixtureId, product.product_usage_id.id, { dose: product.dose }));
      } else if (product.toCreate) {
        promises.push(
          Api.addPhytoMixtureProduct(mixtureId, {
            product_usage_id: product.product_usage_id.id,
            dose: Number(product.dose),
          }),
        );
      }
    });
  }
  return Promise.all(promises).then(
    () => {
      dispatch(fetchMixtures(payload.clusterId));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const archiveMixture = (mixtureId, clusterId, isCurrentlyArchived) => (dispatch) => {
  return Api.updatePhytoMixture(mixtureId, {
    status: isCurrentlyArchived ? 'active' : 'archived',
  }).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: t('AdminMixture.edit_success_snack'),
          action: 'OK',
        }),
      );
      dispatch(fetchMixtures(clusterId));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};
