import moment from 'moment-timezone';
import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { SNACK_CONSTANTS } from '../constants/snackConstants';
import { I18n } from '../i18n';
import { snackAdded } from '../slices/snacks';

const userInfoReceived = (payload) => ({
  type: Actions.USER_INFO_RETRIEVED,
  payload,
});

export const receiveLogin = (payload) => ({
  type: Actions.LOGIN_SUCCESS,
  payload,
});

export const retrieveMe = () => (dispatch) =>
  Api.retrieveMe().then(
    (me) => {
      dispatch(userInfoReceived(me));
      return me;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const updateProfileSuccess = (payload) => ({
  type: Actions.UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfile = (id, payload) => (dispatch) =>
  Api.updateProfile(id, payload).then(
    (res) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 5000,
          message: I18n.t('Admin.update_user_confirm'),
          action: 'OK',
        }),
      );
      dispatch(updateProfileSuccess(res));
      return res;
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const updateDriverSuccess = (payload) => ({
  type: Actions.UPDATE_DRIVER_SUCCESS,
  payload,
});

export const updateUserInfos = (userID, clusterID, payload) => (dispatch) => {
  const promises = [];
  if (payload?.firstname) {
    promises.push(
      Api.updateProfile(userID, {
        firstname: payload.firstname,
        lastname: payload.lastname,
        phonenumber: payload.phonenumber,
      }),
    );
  }
  if (payload?.role || payload?.certiphyto_number) {
    promises.push(Api.patchUserClusterInfos(userID, clusterID, payload.role, payload.certiphyto_number));
  }
  return Promise.all(promises).then(
    (result) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 5000,
          message: I18n.t('Admin.update_user_confirm'),
          action: 'OK',
        }),
      );
      dispatch(updateDriverSuccess(result[0]));
      return {
        ...result[0],
        role: payload.role ? result[0].role : null,
      };
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const updateOptin = (id, payload) => (dispatch) =>
  Api.updateOptin(id, payload).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 5000,
          message: I18n.t('Settings.notifications_saved'),
          action: 'OK',
        }),
      );
      dispatch(updateProfileSuccess(payload));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const requestUpdatePassword = () => ({
  type: Actions.UPDATE_PASSWORD_REQUEST,
});

const updatePasswordSuccess = (payload) => ({
  type: Actions.UPDATE_PASSWORD_SUCCESS,
  payload,
});

export const updatePassword = (id, payload) => (dispatch) => {
  dispatch(requestUpdatePassword());

  return Api.updatePassword(id, payload).then(
    (res) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 5000,
          message: I18n.t('Admin.update_password_confirm'),
          action: 'OK',
        }),
      );
      dispatch(updatePasswordSuccess(res));
    },
    (e) => {
      dispatch(
        snackAdded({
          type: SNACK_CONSTANTS.TYPE.ERROR,
          message: e?.errors[0]?.message,
          action: 'OK',
        }),
      );
      return e?.errors[0]?.message;
    },
  );
};

export const logoutUser = () => ({
  type: Actions.LOGOUT,
});

const receiveUsersOfCluster = (users, cluster_id) => ({
  type: Actions.RETRIEVE_USERS_OF_CLUSTER_SUCCESS,
  payload: { users, cluster_id },
});

export const fetchUsersOfCluster = (cluster_id) => (dispatch) =>
  Api.getUsersOfCluster(cluster_id).then(
    (res) => dispatch(receiveUsersOfCluster(res, cluster_id)),
    (e) => errorHasOccurred(e, dispatch),
  );

export const userLinkedWithSuccess = (user, clusterId) => ({
  type: Actions.USER_LINK_SUCCESS,
  payload: {
    user: user,
    cluster_id: clusterId,
    user_id: user.id,
  },
});

export const linkUser =
  ({ email, clusterID, role, firstname, lastname, phonenumber, certiphyto_number }) =>
  (dispatch) =>
    Api.linkUserToClusterByMail({ email, clusterID, role, firstname, lastname, phonenumber, certiphyto_number }).then(
      (user) => {
        dispatch(
          snackAdded({
            type: 'success',
            duration: 4000,
            message: I18n.t('Admin.add_user_confirm', { email }),
            action: 'OK',
          }),
        );
        dispatch(userLinkedWithSuccess(user, clusterID));
        return Promise.resolve(user);
      },
      (e) => errorHasOccurred(e, dispatch),
    );

const driverAndBeaconLinked = ({ driver, beaconID, clusterID, history }) => ({
  type: Actions.DRIVER_AND_BEACON_LINKED,
  payload: {
    id: beaconID,
    driverID: driver.id,
    cluster_id: clusterID,
    user: driver,
    history,
  },
});

export const linkDriver =
  ({ beaconID, email, clusterID, role, firstname, lastname, phonenumber }) =>
  (dispatch) =>
    Api.linkUserToClusterByMail({ email, clusterID, role, firstname, lastname, phonenumber }).then(
      (driver) => {
        if (beaconID) {
          Api.linkBeaconToDriver(beaconID, { user_id: driver.id, fromDate: moment().format() }).then(
            (response) => {
              dispatch(
                snackAdded({
                  type: 'success',
                  duration: 3000,
                  message: I18n.t('Admin.add_user_confirm', { email }),
                  action: 'OK',
                }),
              );
              dispatch(driverAndBeaconLinked({ driver, beaconID, clusterID, history: response }));
            },
            (e) => errorHasOccurred(e, dispatch),
          );
        } else {
          dispatch(
            snackAdded({
              type: 'success',
              duration: 3000,
              message: I18n.t('Admin.add_user_confirm', { email }),
              action: 'OK',
            }),
          );
          dispatch(userLinkedWithSuccess(driver, clusterID));
        }
      },
      (e) => errorHasOccurred(e, dispatch),
    );

const receiveUsers = (payload) => ({
  type: Actions.RECEIVE_USERS,
  payload,
});

export const fetchUsers = () => (dispatch) =>
  Api.getUsers().then(
    (users) => {
      dispatch(receiveUsers(users));
      return Promise.resolve(users);
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const partnerTokenSuccess = (partnerName = 'smag') => ({
  type: Actions.PARTNER_TOKEN_SUCCESS,
  partnerName,
});

export const postPartnerToken =
  (partnerName = 'smag', payload) =>
  (dispatch) =>
    Api.postPartnerToken(partnerName, payload).then(
      () => {
        dispatch(partnerTokenSuccess(partnerName));
        return Promise.resolve();
      },
      (e) => errorHasOccurred(e, dispatch),
    );

const hasPartnerTokenSuccess = (partnerName = 'smag') => ({
  type: Actions.HAS_PARTNER_TOKEN_SUCCESS,
  partnerName,
});

export const hasPartnerToken =
  (partnerName = 'smag') =>
  (dispatch) =>
    Api.hasPartnerToken(partnerName).then(
      () => {
        dispatch(hasPartnerTokenSuccess(partnerName));
        return Promise.resolve();
      },
      (e) => {
        // no need to display error if the user is not logged to smag
        const isAuthorizationError = !!e?.errors?.find((error) => error.reason === 'authorizationError');
        if (!isAuthorizationError) {
          return errorHasOccurred(e, dispatch);
        }
      },
    );

// Tags

const linkedExistingTagToUser = (userId, tag) => ({
  type: Actions.USER_LINK_EXISTING_TAG,
  payload: { userId, tag },
});

const unlinkedTagFromUser = (userId, tagId) => ({
  type: Actions.USER_UNLINK_TAG,
  payload: { userId, tagId },
});

export const linkTagToUser = (userId, tag) => (dispatch) => {
  return Api.linkTagToUser(userId, tag.id)
    .then(() => dispatch(linkedExistingTagToUser(userId, tag)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const unlinkTagFromUser = (userId, tagId) => (dispatch) => {
  return Api.unlinkTagFromUser(userId, tagId)
    .then(() => dispatch(unlinkedTagFromUser(userId, tagId)))
    .catch((e) => errorHasOccurred(e, dispatch));
};
