import { useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { colors } from '@karnott/colors';
import { LOCALE_FORMAT } from '../../../../constants';
import { EQUIPMENT_CONSTANTS } from '../../../../constants/equipmentConstants';
import { I18nContext } from '../../../../contexts/I18nProvider';
import { UIContext } from '../../../../contexts/ui';
import { I18n } from '../../../../i18n';
import { getBatteryLevel, hasCriticalHealth, isCharging } from '../../../../utils/devices';
import { equipmentTypeLabel, getEquipmentIcon } from '../../../../utils/equipments';
import { useDrawMarkerOnMap } from '../../effects';
import { openEquipmentTooltip } from './events';

// get equipment icon data to build marker on map
export function useEquipmentIconData({ equipment, focused }) {
  return useMemo(() => {
    return equipment
      ? {
          location: { latitude: equipment.latitude, longitude: equipment.longitude },
          Icon: equipment.equipmentModel
            ? getEquipmentIcon(equipment.equipmentModel.type)
            : getEquipmentIcon(EQUIPMENT_CONSTANTS.TYPES.misc),
          iconColor: colors('white'),
          backgroundColor: focused
            ? colors('black', 'dark')
            : equipment.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE
              ? colors('green')
              : colors('grey', 'light'),
          healthIssue: hasCriticalHealth(equipment.activeDevice),
        }
      : {};
  }, [focused, equipment]);
}
export function useDrawEquipmentOnMap(map, equipment, events) {
  const { focusedEquipmentId, hideEquipmentTooltip } = useContext(UIContext);
  const { t } = useContext(I18nContext);
  const history = useHistory();
  const focused = useMemo(() => {
    return focusedEquipmentId === equipment.id;
  }, [focusedEquipmentId, equipment.id]);

  const { location, Icon, iconColor, backgroundColor, healthIssue } = useEquipmentIconData({ equipment, focused });
  const marker = useDrawMarkerOnMap(map, equipment, location, Icon, iconColor, backgroundColor, events, healthIssue);
  const openedTooltipLayer = useRef(null);
  const labels = useEquipmentTooltipLabels();
  const equipmentProps = useMemo(() => equipmentTooltipData(equipment, history), [equipment, history]);

  useEffect(() => {
    const { layerGroup, iconMarker, backgroundMarker } = marker;
    if (equipment.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE) {
      iconMarker?.setZIndexOffset(9000);
      backgroundMarker?.setZIndexOffset(9000);
    }

    if (!focusedEquipmentId || focusedEquipmentId !== equipment.id || !layerGroup || !iconMarker || !backgroundMarker)
      return () => {};
    openedTooltipLayer.current = marker;
    !hideEquipmentTooltip && queueMicrotask(() => openEquipmentTooltip(equipmentProps, iconMarker, labels));

    iconMarker.setZIndexOffset(10000);
    backgroundMarker.setZIndexOffset(10000);

    return () => {
      if (openedTooltipLayer.current) {
        openedTooltipLayer.current.iconMarker.closePopup();
        iconMarker.setZIndexOffset(equipment.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE ? 9000 : 0);
        backgroundMarker.setZIndexOffset(equipment.status === EQUIPMENT_CONSTANTS.EQUIPMENT_STATUS.INUSE ? 9000 : 0);
        openedTooltipLayer.current = null;
      }
    };
  }, [
    focusedEquipmentId,
    labels,
    marker,
    openedTooltipLayer,
    Icon,
    equipment,
    t,
    equipmentProps,
    hideEquipmentTooltip,
  ]);
}

export function useEquipmentCoords(equipments = []) {
  return useMemo(() => {
    return (
      equipments.length &&
      equipments
        .filter((e) => e.latitude && e.longitude)
        .reduce((value, equipment) => {
          return value.concat([[equipment.latitude, equipment.longitude]]);
        }, [])
    );
  }, [equipments]);
}

export function useEquipmentTooltipLabels() {
  const { t } = useContext(I18nContext);
  return useMemo(
    () => ({
      beacon: t('Commons.beacon'),
      driver_beacon: t('Commons.driver_beacon'),
      device: t('Commons.device'),
      last_position: t('Tooltip.last_position'),
      name: t('Tooltip.name'),
      active: t('Commons.active'),
      inactive: t('Commons.inactive'),
      see: t('Commons.see'),
      diagnose: t('Tooltip.diagnose'),
    }),
    [t],
  );
}

export function equipmentTooltipData(equipment = {}, history) {
  const Icon = equipment.equipmentModel ? getEquipmentIcon(equipment.equipmentModel.type) : getEquipmentIcon('misc');
  return {
    Icon: Icon,
    name: equipment.label,
    isActive: equipment.status === 'IN_USE',
    type: I18n.t(equipmentTypeLabel(equipment.equipmentModel?.type)),
    cluster: equipment.cluster?.name || '',
    lastDate: moment(equipment.locationDate).format(LOCALE_FORMAT),
    deviceType: equipment.activeDevice ? 'karnott' : equipment.activeBeacon ? 'beacon' : null,
    deviceName: equipment.activeDevice?.name || equipment.activeBeacon?.readableUuid,
    deviceBattery: getBatteryLevel(equipment.activeDevice),
    batteryCharging: isCharging(equipment.activeDevice?.chargingState),
    equipmentId: equipment.id,
    healthIssue: hasCriticalHealth(equipment.activeDevice),
    history,
  };
}
