import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { colors } from '@karnott/colors';
import { GOOGLE_MAX_ZOOM, GOOGLE_MUTANT_TILES, IGN_LAYER, IGN_MAX_ZOOM } from '../../../../constants/mapConstants';
import { PluginsContext } from '../../../../contexts/plugins';
import { I18n } from '../../../../i18n';
import { ANALYTICS, sendAnalyticsBtnClickEvent } from '../../../../utils/AnalyticsConstants';
import { L as Leaflet } from '../../../../utils/LeafletOverrides';
import { localStorageKeys } from '../../../../utils/storage';
import { useMapCarrier, useMapInCarrier } from '../../effects';
import Context from './Context';
import { useRegionBinder } from './effects';

export default function Provider({ children, id, tiles }) {
  const carrier = useMapCarrier({ id });

  const { hasOverallIgnTilesPlugin } = useContext(PluginsContext);
  const initialTiles = useMemo(() => {
    if (hasOverallIgnTilesPlugin) {
      return tiles;
    }
    return GOOGLE_MUTANT_TILES;
  }, [hasOverallIgnTilesPlugin, tiles]);

  const [map, changeTiles] = useMapInCarrier({ carrier, tiles: initialTiles });

  const initialUseIgn = useRef(localStorage.getItem(localStorageKeys.ign) === 'true');
  useEffect(() => {
    if (initialUseIgn.current) {
      map.setMaxZoom(IGN_MAX_ZOOM);
    } else {
      map.setMaxZoom(GOOGLE_MAX_ZOOM);
    }
  }, [map]);
  const [useIGN, setIGN] = useState(localStorage.getItem(localStorageKeys.ign) === 'true');

  const toggleTiles = useCallback(() => {
    if (useIGN) {
      map.setMaxZoom(GOOGLE_MAX_ZOOM);
      sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.MAP.SATELLITE_TILES);
      changeTiles(GOOGLE_MUTANT_TILES);
      setIGN(false);
    } else {
      map.setMaxZoom(IGN_MAX_ZOOM);
      sendAnalyticsBtnClickEvent(window)(ANALYTICS.LABEL.MAP.IGN_TILES);
      changeTiles(IGN_LAYER);
      setIGN(true);
    }
  }, [changeTiles, useIGN, map]);

  const regionBinder = useRegionBinder(map);

  const line = useMemo(
    () =>
      new Leaflet.Draw.Polyline(map, {
        shapeOptions: {
          color: colors('orange'),
          weight: 4,
        },
      }),
    [map],
  );

  const poly = useMemo(
    () =>
      new Leaflet.Draw.Polygon(map, {
        shapeOptions: {
          color: colors('orange'),
          fillColor: colors('green'),
          opacity: 0.5,
          weight: 2,
        },
        allowIntersection: false,
        touchIcon: new Leaflet.DivIcon({
          iconSize: new Leaflet.Point(6, 6),
          className: 'leaflet-div-icon leaflet-editing-icon',
        }),
        icon: new Leaflet.DivIcon({
          iconSize: new Leaflet.Point(6, 6),
          className: 'leaflet-div-icon leaflet-editing-icon',
        }),
        drawError: {
          message: I18n.t('DrawParcel.intersects_unallocated'),
        },
      }),
    [map],
  );

  return (
    <Context.Provider
      value={{
        line,
        map,
        carrier,
        regionBinder,
        poly,
        toggleTiles,
      }}
    >
      {children}
    </Context.Provider>
  );
}
