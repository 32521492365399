import { useCallback, useMemo } from 'react';
import { colors } from '@karnott/colors';
import { BeaconIcon, KarnottIcon } from '@karnott/icons';
import { hasCriticalHealth } from '../../../../utils/devices';
import { useDrawMarkersOnMap } from '../../effects';

export function useDrawDevicesOnMap(map, devices, events) {
  const dataExtractor = useCallback((d) => {
    const isBeacon = !!d.type;
    return {
      location: { latitude: d.locationLatitude, longitude: d.locationLongitude },
      Icon: isBeacon ? BeaconIcon : KarnottIcon,
      iconColor: colors('white'),
      backgroundColor: d.status === 'IN_USE' ? colors('green') : colors('black', 'dark'),
      healthIssue: !isBeacon && hasCriticalHealth(d),
      iconSize: 34,
      iconAnchor: [19.5, 42],
    };
  }, []);
  useDrawMarkersOnMap(map, devices, dataExtractor, events);
}

export function useDeviceCoords(devices = []) {
  return useMemo(() => {
    return devices
      .filter((d) => d.locationLatitude && d.locationLongitude)
      .reduce((value, device) => {
        return value.concat([[device.locationLatitude, device.locationLongitude]]);
      }, []);
  }, [devices]);
}
