export const MarkerIcon = function ({ length, size, color = '#B1B1B1', borderColor = '#fff', ...svgProps }) {
  const defaultHeight = 50;
  const stroke = 4;
  return (
    <svg
      viewBox={`0 0 ${defaultHeight * length} ${defaultHeight}`}
      height={`${size || 24}`}
      style={{ overflow: 'visible' }}
      {...svgProps}
    >
      <g>
        {/* border */}
        <rect
          fill={borderColor}
          x="0"
          y="0"
          height={`${defaultHeight}`}
          width={`${defaultHeight * length}`}
          rx={`${defaultHeight / 2}`}
          ry={`${defaultHeight / 2}`}
        ></rect>
        {/* center circle */}
        <rect
          fill={color}
          x={`${stroke}`}
          y={`${stroke}`}
          height={`${defaultHeight - stroke * 2}`}
          width={`${defaultHeight * length - stroke * 2}`}
          rx={`${(defaultHeight - stroke * 2) / 2}`}
          ry={`${(defaultHeight - stroke * 2) / 2}`}
        ></rect>
        <g>
          {/* arrow */}
          <path
            fill={borderColor}
            d={`M${(defaultHeight * length) / 2 - 8} ${defaultHeight - 2} L${(defaultHeight * length) / 2 + 8} ${
              defaultHeight - 2
            } L${(defaultHeight * length) / 2} ${defaultHeight + 5} Z`}
          />
          {/* shadow */}
          <ellipse
            opacity="0.25"
            fill="#141105"
            cx={`${(defaultHeight * length) / 2}`}
            cy={`${defaultHeight + 6}`}
            rx="5.9"
            ry="0.2"
          />
        </g>
      </g>
    </svg>
  );
};
