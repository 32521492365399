import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { openLeafletPopup } from '../../../../utils/map';
import { worksiteTooltipData } from './effects';
import { WorksiteTooltip } from './tooltip';

export function openWorksiteTooltip(worksiteProps, layer, labels, latlng, withOffset = true) {
  const tooltip = <WorksiteTooltip {...{ worksiteProps, labels }} />;
  const iconLength = worksiteProps?.Icons?.length;
  const popupOptions = {
    direction: 'top',
    offset: withOffset ? [-3 * iconLength, -38] : [0, 0],
    closeButton: false,
    interactive: true,
    maxWidth: 400,
  };
  openLeafletPopup(layer, tooltip, popupOptions, latlng);
}

export function useWorksiteEventsRef(labels, setOverTrackID) {
  const history = useHistory();

  return useRef({
    mouseover: (worksite, l, { iconMarker, backgroundMarker }) => {
      if (worksite?.tracks) {
        setOverTrackID(worksite.device_session_id);
      }
      const wkProps = worksiteTooltipData(worksite, history);
      openWorksiteTooltip(wkProps, iconMarker, labels);
      iconMarker.setZIndexOffset(10000);
      backgroundMarker.setZIndexOffset(10000);
    },
    mouseout: (worksite, l, { iconMarker, backgroundMarker }) => {
      if (worksite?.tracks) {
        setOverTrackID(null);
      }
      iconMarker.setZIndexOffset(9000);
      backgroundMarker.setZIndexOffset(9000);
    },
    click: (w) => {
      const karnottSession = w.sessions?.find((s) => !s.beacon_uuid);
      karnottSession &&
        history?.push(`/equipments/${karnottSession.equipment_instance_id}/sessions/${karnottSession.id}`);
    },
  });
}
