import { PERMISSIONS_KEYS_BY_ACTION, Permission, Permissions } from '../constants/permissionsConstants';

export type ItemWithPermissions = {
  authorizations: Permissions;
};

export function hasPermission<Item extends ItemWithPermissions>(item: Item, key: Permission) {
  if (item?.authorizations) {
    return Array.isArray(item?.authorizations?.[key])
      ? item.authorizations[key].length > 0
      : item.authorizations[key] === true;
  }
  return false;
}

export function filterListByAuthorizationField<Item extends ItemWithPermissions>(list: Item[] = [], key: Permission) {
  const filter = getItemFilterByAuthorizationField(key);
  return list.filter(filter);
}

export function getItemFilterByAuthorizationField<Item extends ItemWithPermissions>(key: Permission) {
  return (item: Item) => {
    return hasPermission(item, key);
  };
}

export function canUpdate<Item extends ItemWithPermissions>(item: Item) {
  return hasPermission(item, PERMISSIONS_KEYS_BY_ACTION.update);
}

export function canDelete<Item extends ItemWithPermissions>(item: Item) {
  return hasPermission(item, PERMISSIONS_KEYS_BY_ACTION.delete);
}
