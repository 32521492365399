import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { size, spacing } from './theme';

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

const StepWrapper = styled.div`
  margin-bottom: ${(props) => (props.inline ? 0 : spacing)}px;
  span {
    svg {
      font-size: ${size('xxl')};
    }
  }
  svg {
    color: ${colors('orange', 'dark')};
  }
`;
const StepIndex = styled.span`
  font-weight: 700;
`;
const StepTitle = styled.div`
  font-weight: 700;
`;

export const ModalLink = styled.a`
  color: ${colors('orange', 'dark')};
  text-decoration: none;
`;

export const Step = function ({ index, inline, content, title, contentIcon, subContent, subContentIcon }) {
  return (
    <StepWrapper inline={inline}>
      {index && (
        <StepIndex>
          {index}
          .&nbsp;
        </StepIndex>
      )}
      {title ? <StepTitle>{title}</StepTitle> : null}
      <span>
        {content} {contentIcon || null}
      </span>
      {subContentIcon && subContent ? (
        <div>
          {subContentIcon} {subContent}
        </div>
      ) : null}
    </StepWrapper>
  );
};
