import { useMemo } from 'react';
import { connect } from 'react-redux';
import { shadeHex } from '@karnott/colors';
import { buildParcelStyle } from '../map/assets/Parcels/style';

function ParcelThumbnail({ parcel, size, parcelsById }) {
  const viewBoxPadding = 2;
  const normalizedCoordinates = useMemo(() => {
    const parcelInStore = parcelsById[parcel.id];
    const coords = parcel?.geometry_area?.coordinates || parcelInStore?.geometry_area?.coordinates;
    const bbox = parcel?.geometry_area?.bbox || parcelInStore?.geometry_area?.bbox;

    if (!coords || !bbox) return [];

    const [minX, minY, maxX, maxY] = bbox;

    return coords.map((coords) => {
      const srcW = maxX - minX;
      const srcH = maxY - minY;
      const dstSize = size - viewBoxPadding * 2;
      const scale = dstSize / Math.max(srcW, srcH);

      const offsetX = (dstSize - srcW * scale) / 2;
      const offsetY = (dstSize - srcH * scale) / 2;

      return coords.map(([x, y]) => {
        const scaledX = (x - minX) * scale;
        const scaledY = (y - minY) * scale;

        return [scaledX + offsetX + viewBoxPadding, dstSize - (scaledY + offsetY) + viewBoxPadding];
      });
    });
  }, [parcel, parcelsById, size]);

  const svgCoordinates = useMemo(
    () => normalizedCoordinates.map((d) => 'M' + d.map((c) => c.join(',')).join(' ') + 'Z').join(' '),
    [normalizedCoordinates],
  );

  const style = buildParcelStyle(parcel);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      style={{ flexShrink: 0 }}
    >
      <path
        strokeWidth={2}
        strokeLinejoin="round"
        stroke={style.fillColor}
        fill={shadeHex(style.fillColor, style.fillOpacity)}
        d={svgCoordinates}
      />
    </svg>
  );
}

const mapStateToProps = (state) => ({
  parcelsById: state.parcels.parcelById,
});

const ConnectedParcelThumbnail = connect(mapStateToProps)(ParcelThumbnail);
export default ConnectedParcelThumbnail;
