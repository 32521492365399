import { MouseEventHandler, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { PARCELS_CONSTANTS } from '@karnott/constants';
import { PlotHooks } from '@karnott/hooks';
import { AreaIcon, ClusterIcon, CropIcon, GrapeVarietyIcon } from '@karnott/icons';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const ParcelTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily()};
`;

const ParcelTooltipRowHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Content = styled.div`
  margin-top: 10px;
  margin-left: 12px;
`;

const ParcelTooltipRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  font-size: 13px;
  margin-bottom: 5px;
`;

const ParcelTooltipRowColumn = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
`;

const ParcelTooltipRowColumnTenant = styled(ParcelTooltipRowColumn)`
  margin-left: 2px;
`;

const ParcelTooltipRowAction = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: right;
  height: 24px;
`;

const ParcelTooltipRowLabel = styled.div`
  font-weight: 700;
  margin-left: ${pixelSpacing('small')};
  font-size: ${pixelSize('large')};
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
`;

const TenantLabel = styled.div`
  margin-left: 2px;
`;

type Props = {
  /** Name of the crop */
  crop?: string;
  /** Color of the parcel icon */
  iconColor?: string;
  /** Name of the tenant */
  tenant?: string;
  /** Category of the parcel */
  category: (typeof PARCELS_CONSTANTS.CATEGORY)[keyof typeof PARCELS_CONSTANTS.CATEGORY];
  /** Formatted area of the parcel */
  area: string;
  /** Current status of the parcel */
  status: (typeof PARCELS_CONSTANTS.STATUS)[keyof typeof PARCELS_CONSTANTS.STATUS];
  /** Name of the parcel */
  name: string;
  /** Action to be performed on chip click */
  action: MouseEventHandler<HTMLButtonElement>;
  /** Whether the account has the crop plugin */
  hasCropPlugin?: boolean;
  /** I18n labels */
  labels: {
    crop_undefined: string;
    see: string;
  };
  /** Whether the account is a viticulture account */
  isViti?: boolean;
  /** Whether to show only the header of the popup */
  headerOnly?: boolean;
};

export function ParcelPopup({
  crop,
  iconColor,
  tenant,
  category,
  area,
  status,
  name,
  action,
  hasCropPlugin = false,
  labels,
  isViti = false,
  headerOnly = false,
}: Props) {
  const [Icon, deductibleIconColor] = PlotHooks.usePlotIconAndColor({ category: category, status: status });
  const iconBgColor = useMemo(() => iconColor || deductibleIconColor, [deductibleIconColor, iconColor]);

  return (
    <ParcelTooltipWrapper>
      <ParcelTooltipRowHeader>
        <IconContainer>
          <Icon backgroundColor={iconBgColor} color={colors('white')} size={40} circled />
        </IconContainer>
        <ParcelTooltipRowLabel>{name}</ParcelTooltipRowLabel>
      </ParcelTooltipRowHeader>
      <Content>
        {!headerOnly && (
          <ParcelTooltipRow>
            <ParcelTooltipRowColumn>
              <AreaIcon size={16} />
              <span>{area}</span>
            </ParcelTooltipRowColumn>
            {hasCropPlugin && category !== PARCELS_CONSTANTS.CATEGORY.FARMHOUSE && (
              <ParcelTooltipRowColumn>
                {isViti ? <GrapeVarietyIcon size={14} /> : <CropIcon size={16} />}
                <span>{crop || labels.crop_undefined}</span>
              </ParcelTooltipRowColumn>
            )}
          </ParcelTooltipRow>
        )}

        {tenant && (
          <ParcelTooltipRow>
            <ParcelTooltipRowColumnTenant>
              <ClusterIcon size={12} />
              <TenantLabel>{tenant}</TenantLabel>
            </ParcelTooltipRowColumnTenant>
          </ParcelTooltipRow>
        )}
      </Content>
      {!headerOnly && (
        <ParcelTooltipRowAction>
          <Button primary rounded thin title={labels.see} onClick={action} />
        </ParcelTooltipRowAction>
      )}
    </ParcelTooltipWrapper>
  );
}
