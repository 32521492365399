import pickBy from 'lodash-es/pickBy';
import { createSelector } from '@reduxjs/toolkit';
import { CLUSTER_CONSTANTS } from '../constants/clusterConstants';
import { PERMISSIONS_KEYS_BY_ACTION } from '../contexts/permissions';
import { getClusters } from './index';

export const getClustersAsList = createSelector([getClusters], (clusters) =>
  clusters.clustersIds.map((id) => clusters.clusterById[id]),
);

const getAdminClusters = createSelector([getClustersAsList], (clusters) => clusters.filter((c) => c.role === 'ADMIN'));

export const getClustersWithRole = createSelector([getClustersAsList], (clusters) =>
  clusters.filter((c) => ['ADMIN', 'DRIVER', 'IMPLEMENT_MANAGER', 'SEASONAL_DRIVER'].includes(c.role)),
);

export const getParentsClusters = createSelector([getClusters], (clusters) => {
  const { clusterById, clustersIds } = clusters;
  const theClusters = clustersIds.map((id) => clusterById[id]);
  // If there is only one cluster and it's a farm, we must consider it as a cluster parent
  if (theClusters.length === 1 && theClusters[0].cluster_type === 'FARM') {
    return theClusters;
  }
  let clusterTypes = ['CUMA', 'ETA', 'CONCESSIONNAIRE'];
  return theClusters.filter((cluster) => clusterTypes.includes(cluster.cluster_type));
});

export const getClusterForCrop = createSelector([getClustersWithRole], (relatedClusters) => {
  if (relatedClusters?.length) {
    return relatedClusters[0];
  }
  return null;
});

const getParentsClustersWithoutLinkedPartner = createSelector([getParentsClusters], (clusters) =>
  clusters.filter((cluster) => cluster.linked_partner === ''),
);

export const getAdminParentsClustersWithoutLinkedPartner = createSelector([getParentsClusters], (clusters) =>
  clusters.filter((cluster) => cluster.role === 'ADMIN' && cluster.linked_partner === ''),
);

export const isThereSomeDemoClusters = createSelector(
  [getClustersAsList],
  (clusters) => clusters.filter((c) => c.is_demo === true).length,
);

export const isViticultureContext = createSelector([getAdminClusters], (clusters) =>
  clusters.some((c) => c?.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.viticulture),
);

export const isDeneigementContext = createSelector([getAdminClusters], (clusters) =>
  clusters.some((c) => c?.activities?.length === 1 && c.activities[0] === CLUSTER_CONSTANTS.ACTIVITIES.deneigement),
);

export const getParentsClustersWhichCanCreateClusterMember = createSelector([getParentsClusters], (clusters) => {
  return clusters.filter((c) => c.authorizations && c.authorizations.can_create_cluster_member);
});

export const getClustersWhichCanCreateUser = createSelector([getParentsClustersWithoutLinkedPartner], (clusters) =>
  clusters.filter((c) => c.authorizations?.can_create_user?.length > 0),
);

export const getClusterByIdWhichCanCreateParcel = createSelector([getClusters], (clusters) => {
  return pickBy(clusters.clusterById, (c) => c.authorizations[PERMISSIONS_KEYS_BY_ACTION.create_parcel]);
});
