import { ComponentProps, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { Shade, colors } from '@karnott/colors';
import { CloseIcon } from '@karnott/icons';
import {
  DANGER,
  INFO,
  SUCCESS,
  Severity,
  WARNING,
  getHexColorSeverity,
  msDuration,
  pixelSize,
  pixelSpacing,
  size,
  zIndex as zIndexTheme,
} from '@karnott/theme';

type Props = {
  /** Severity of the modal, to change the colors */
  severity?: Severity;
  /** Content of the modal. */
  children: ReactNode;
  /** Whether the save/OK button is disabled */
  continueDisabled?: boolean;
  /**
   * Id of the modal container (useful for testing). Also identifies the parts of the modal ($-header, $-content,
   * $-footer)
   */
  id?: string;
  /** Callback when the cancel/close button is clicked */
  onClose?: () => void;
  /** Callback when the cancel button is clicked. Defaults to `onClose` */
  onCancel?: () => void;
  /** Whether the modal is open */
  showModal: boolean;
  /** Title of the modal */
  title?: string;
  /** Label of the cancel button */
  cancelLabel?: string;
  /** Label of the save/OK button */
  saveLabel: string;
  /** Callback when the save/OK button is clicked */
  onSave: MouseEventHandler<HTMLButtonElement>;
  /** Base width of the modal */
  width?: string;
  /** Max width of the modal */
  maxWidth?: string;
  /** Show a loader on the save/OK button */
  isOnSaveLoading?: boolean;
  /** Allow content to overflow (useful for list selectors) */
  allowContentOverflow?: boolean;
  /** Right button group definition */
  rightButtons?: ComponentProps<typeof Button>[];
  /** Disable the default padding on the content */
  noPadding?: boolean;
  /** Z-index of the modal, to reorder nested modals not opening correctly */
  zIndex?: number;
};

const noop = () => {};

/** A modal that opens in the center of the page, to quickly view or input information */
export const CentralModal = function ({
  severity = INFO,
  children,
  continueDisabled = false,
  id,
  onClose,
  onCancel,
  showModal,
  title,
  cancelLabel,
  saveLabel,
  onSave,
  width = '35em',
  maxWidth,
  isOnSaveLoading = false,
  allowContentOverflow = false,
  rightButtons,
  noPadding = false,
  zIndex = zIndexTheme('modal'),
}: Props) {
  const definedOnClose = onClose || noop;
  const definedOnCancel = onCancel || definedOnClose;

  const definedBackgroundColor = useMemo(() => {
    let shade: Shade = 500;
    if (!severity || severity === INFO) {
      shade = 100;
    }
    return getHexColorSeverity(severity, shade);
  }, [severity]);

  const definedColor = useMemo(() => {
    switch (severity) {
      case INFO:
        return colors('black');
      case DANGER:
        return colors('white');
      case WARNING:
        return colors('white');
      case SUCCESS:
        return colors('white');
      default:
        return colors('black');
    }
  }, [severity]);

  const onEscPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        definedOnClose();
      }
    },
    [definedOnClose],
  );

  useEffect(() => {
    showModal && document.addEventListener('keyup', onEscPress);
    return () => {
      document.removeEventListener('keyup', onEscPress);
    };
  }, [onEscPress, showModal]);

  return (
    <>
      {createPortal(
        <CentralModalContainer show={showModal} verticallyCentered={!allowContentOverflow} zIndex={zIndex}>
          <CentralModalBackground
            $width={width}
            maxWidth={maxWidth}
            show={showModal}
            allowOverflow={allowContentOverflow}
            id={id}
          >
            {showModal ? (
              <>
                {title ? (
                  <CentralModalHeader
                    backgroundColor={definedBackgroundColor}
                    $color={definedColor}
                    id={id ? `${id}-header` : undefined}
                  >
                    <CentralModalTitle>{title}</CentralModalTitle>
                    {onClose ? (
                      <CentralModalClose onClick={definedOnClose}>
                        <CloseIcon size={size('large')} color={definedColor} />
                      </CentralModalClose>
                    ) : null}
                  </CentralModalHeader>
                ) : null}
                <CentralModalContent
                  id={id ? `${id}-content` : undefined}
                  allowOverflow={allowContentOverflow}
                  noPadding={noPadding}
                >
                  {children}
                </CentralModalContent>
                {cancelLabel || saveLabel || rightButtons?.length ? (
                  <CentralModalFooter id={id ? `${id}-footer` : undefined}>
                    <div>{cancelLabel ? <Button outlined title={cancelLabel} onClick={definedOnCancel} /> : null}</div>
                    <ButtonGroup>
                      {rightButtons?.length ? (
                        rightButtons.map((buttonProps, i) => <Button key={i} {...buttonProps} />)
                      ) : saveLabel ? (
                        <Button
                          {...{ [severity]: true, loading: isOnSaveLoading }}
                          success={severity === INFO || severity === SUCCESS}
                          title={saveLabel}
                          onClick={onSave}
                          disabled={continueDisabled}
                        />
                      ) : null}
                    </ButtonGroup>
                  </CentralModalFooter>
                ) : null}
              </>
            ) : null}
          </CentralModalBackground>
        </CentralModalContainer>,
        document.body,
      )}
    </>
  );
};

const CentralModalContainer = styled.div<{
  show: boolean;
  verticallyCentered: boolean;
  zIndex: number;
}>`
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  width: ${(props) => (props.show ? '100vw' : '0')};
  transition: background-color ${msDuration()};
  background-color: ${(props) => (props.show ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)')};
  z-index: ${({ zIndex }) => zIndex};
  overflow: scroll;
  align-items: center;
  justify-content: ${({ verticallyCentered }) => (verticallyCentered ? 'center' : 'flex-start')};
  padding: ${({ show }) => (show ? pixelSpacing() : 0)};
`;

const CentralModalBackground = styled.div<{
  show: boolean;
  $width?: string;
  maxWidth?: string;
  allowOverflow: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: ${({ allowOverflow }) => (allowOverflow ? '10vh' : 0)};
  margin-bottom: ${({ allowOverflow }) => (allowOverflow ? '10vh' : 0)};
  opacity: ${({ show }) => (show ? 1 : 0)};
  width: ${({ $width }) => ($width ? $width : '648px')};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'unset')};
  background-color: ${colors('grey', 100)};
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'unset' : 'auto')};
  transition: opacity ${msDuration()} ease-in;
  border-radius: 4px;
`;

const CentralModalContent = styled.div<{
  allowOverflow: boolean;
  noPadding: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${pixelSpacing('regular')} 0;
  padding: ${({ noPadding }) => (noPadding ? 0 : pixelSpacing())};
  overflow-y: ${({ allowOverflow }) => (allowOverflow ? 'unset' : 'auto')};
  :empty {
    display: none;
  }
`;

const CentralModalTitle = styled.h3`
  padding: 0;
  margin: 0;
  font-weight: bold;
  font-size: 19px;
`;

const CentralModalHeader = styled.div<{
  backgroundColor: string;
  $color: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ $color }) => $color};
  padding: ${pixelSpacing()};
  border-bottom: 1px solid ${colors('grey', 200)};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const CentralModalClose = styled.div`
  display: flex;
  font-size: ${pixelSize('xLarge')};
  cursor: pointer;
  margin-left: 45px;
`;

const CentralModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${pixelSpacing('small')};
  align-items: center;
  padding: ${pixelSpacing()};
  border-top: 1px solid ${colors('grey', 200)};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${pixelSpacing()};
`;
