import flattenDeep from 'lodash-es/flattenDeep';
import { replace } from 'redux-first-history';
import { Actions, errorHasOccurred } from '.';
import * as Api from '../api/api';
import { equipmentInstance, equipmentModel, equipmentSession } from '../api/schema';
import { snackAdded } from '../slices/snacks';
import { normalize } from '../utils';

const requestEquipments = () => ({
  type: Actions.EQUIPMENTS_REQUEST,
});

const receiveEquipments = (payload) => ({
  type: Actions.EQUIPMENTS_SUCCESS,
  payload,
});

export const fetchEquipments = (payload) => (dispatch) => {
  dispatch(requestEquipments());

  return Api.getEquipments(payload).then(
    (response) => dispatch(receiveEquipments(normalize(response, [equipmentInstance]))),
    (e) => errorHasOccurred(e, dispatch),
  );
};

const receiveEquipment = (payload) => ({
  type: Actions.EQUIPMENT_SUCCESS,
  payload,
});

export const fetchEquipment = (payload) => (dispatch) =>
  Api.getEquipment(payload).then(
    (response) => dispatch(receiveEquipment(normalize(response, equipmentInstance))),
    (e) => errorHasOccurred(e, dispatch),
  );

const requestEquipmentModels = () => ({
  type: Actions.EQUIPMENT_MODELS_REQUEST,
});

const receiveEquipmentModels = (payload) => ({
  type: Actions.EQUIPMENT_MODELS_SUCCESS,
  payload,
});

export const fetchEquipmentModels = () => (dispatch) => {
  dispatch(requestEquipmentModels());

  return Api.getEquipmentModels().then(
    (response) => dispatch(receiveEquipmentModels(normalize(response, [equipmentModel]))),
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestCreateEquipment = () => ({
  type: Actions.EQUIPMENT_CREATION_REQUEST,
});

export const createEquipment = (payload) => (dispatch) => {
  dispatch(requestCreateEquipment());

  return Api.createEquipment(payload).then(
    (e) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: 'L’équipement a bien été créé',
          action: 'OK',
        }),
      );
      dispatch(fetchEquipments());
      return e;
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

const requestEquipmentSessions = (id) => ({
  type: Actions.EQUIPMENT_SESSIONS_REQUEST,
  payload: id,
});

const receiveEquipmentSessions = (payload) => ({
  type: Actions.EQUIPMENT_SESSIONS_SUCCESS,
  payload,
});

export const fetchEquipmentSessions =
  ({ id, start, end, format, params, signal }) =>
  (dispatch) => {
    dispatch(requestEquipmentSessions(id));

    return Api.getEquipmentSessions({
      id,
      start,
      end,
      format,
      params,
      signal,
    }).then(
      (response) => {
        if (format === 'xlsx' || format === 'csv') {
          return response;
        }
        return dispatch(
          receiveEquipmentSessions({
            id,
            data: normalize(flattenDeep(response?.map((r) => r.equipment_sessions)), [equipmentSession]),
          }),
        );
      },
      (error) => {
        if (error.name !== 'AbortError') {
          return errorHasOccurred(error, dispatch);
        }
      },
    );
  };

export const fetchConsolidatedEquipmentSessions =
  ({ id, start, end, format, params }) =>
  (dispatch) =>
    Api.getConsolidatedEquipmentSessions({
      id,
      start,
      end,
      format,
      params,
    }).then(
      (response) => {
        if (format === 'xlsx' || format === 'csv') {
          return response;
        }
      },
      (e) => errorHasOccurred(e, dispatch),
    );

export const editEquipment = (id, payload) => (dispatch) =>
  Api.editEquipment(id, payload).then(
    (result) => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: 'Votre matériel a été édité avec succès',
          action: 'OK',
        }),
      );
      return dispatch(receiveEquipment(normalize(result, equipmentInstance)));
    },
    (e) => errorHasOccurred(e, dispatch),
  );

const requestDeleteEquipment = (id) => ({
  type: Actions.EQUIPMENT_DELETE_REQUEST,
  payload: id,
});

const receiveDeleteEquipment = (id) => ({
  type: Actions.EQUIPMENT_DELETE_SUCCESS,
  payload: id,
});

export const deleteEquipment = (id) => (dispatch) => {
  dispatch(requestDeleteEquipment(id));

  return Api.deleteEquipment(id).then(
    () => {
      dispatch(
        snackAdded({
          type: 'success',
          duration: 3000,
          message: 'L’équipement a bien été supprimé',
          action: 'OK',
        }),
      );
      dispatch(replace('/equipments'));
      dispatch(receiveDeleteEquipment(id));
    },
    (e) => errorHasOccurred(e, dispatch),
  );
};

export const removeCurrentDeviceToEquipment = (id) => ({
  type: Actions.REMOVE_CURRENT_DEVICE_TO_EQUIPMENT,
  payload: id,
});

// Tags

const linkedExistingTagToEquipment = (equipmentId, tag) => ({
  type: Actions.EQUIPMENT_LINK_EXISTING_TAG,
  payload: { equipmentId, tag },
});

const unlinkedTagFromEquipment = (equipmentId, tagId) => ({
  type: Actions.EQUIPMENT_UNLINK_TAG,
  payload: { equipmentId, tagId },
});

export const linkTagToEquipment = (equipmentId, tag) => (dispatch) => {
  return Api.linkTagToEquipment(equipmentId, tag.id)
    .then(() => dispatch(linkedExistingTagToEquipment(equipmentId, tag)))
    .catch((e) => errorHasOccurred(e, dispatch));
};

export const unlinkTagFromEquipment = (equipmentId, tagId) => (dispatch) => {
  return Api.unlinkTagFromEquipment(equipmentId, tagId)
    .then(() => dispatch(unlinkedTagFromEquipment(equipmentId, tagId)))
    .catch((e) => errorHasOccurred(e, dispatch));
};
