import { createAppSlice } from '.';
import {
  createCrop as createApiCrop,
  deleteCrop as deleteApiCrop,
  getCrops,
  updateCrop as updateApiCrop,
} from '../api/api';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { alerting } from './snacks';

type CropId = string;

type BasicCrop = ItemWithPermissions & {
  id: CropId;
  name: string;
  crop_id: number;
  color: string;
  code: string;
  locale: string;
  provenance: string;
  cluster_id: undefined;
};

type CustomCrop = ItemWithPermissions & {
  id: CropId;
  name: string;
  crop_id: number;
  base_crop?: string;
  color: string;
  code: '';
  locale: undefined;
  provenance: undefined;
  cluster_id: ClusterId;
};

type Crop = BasicCrop | CustomCrop;

type State = {
  cropByID: Record<CropId, Crop>;
  cropIDs: CropId[];
};

const initialState: State = {
  cropByID: {},
  cropIDs: [],
};

const cropsSlice = createAppSlice({
  name: 'crops',
  initialState,
  reducers: (create) => ({
    fetchCrops: create.asyncThunk(
      async () => {
        const crops = (await alerting(getCrops)) as Crop[];
        return crops;
      },
      {
        fulfilled: (state, { payload }) => {
          for (const crop of payload) {
            state.cropByID[crop.id] = crop;
            state.cropIDs.push(crop.id);
          }
        },
      },
    ),
    createCrop: create.asyncThunk(
      async (payload: CustomCrop) => {
        const newCrop = (await alerting(() => createApiCrop(payload))) as Crop;
        return newCrop;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropByID[payload.id] = payload;
          state.cropIDs.push(payload.id);
        },
      },
    ),
    updateCrop: create.asyncThunk(
      async (payload: PartialWithRequired<Crop, 'id'>) => {
        const updatedCrop = (await alerting(() => updateApiCrop(payload.id, payload))) as Crop;
        return updatedCrop;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropByID[payload.id] = payload;
        },
      },
    ),
    deleteCrop: create.asyncThunk(
      async (payload: CropId) => {
        await alerting(() => deleteApiCrop(payload));
        return payload;
      },
      {
        fulfilled: (state, { payload }) => {
          state.cropIDs = state.cropIDs.filter((id) => id !== payload);
          delete state.cropByID[payload];
        },
      },
    ),
  }),
});

export const { fetchCrops, createCrop, updateCrop, deleteCrop } = cropsSlice.actions;

export default cropsSlice.reducer;
