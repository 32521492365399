import { useCallback, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { Actions } from '../../actions';
import { useTracksHighlightStyle } from '../../components/map/assets/Tracks/effects';
import {
  isThereSomeDemoClusters as demoClustersSelector,
  isDeneigementContext,
  isViticultureContext,
} from '../../selectors/clusters';
import { localStorageKeys } from '../../utils/storage';
import { handleDateURLParams, initDatesFromURL } from '../../utils/url';
import { Context } from './context';

const initialState = {
  fromDate: initDatesFromURL().fromDate,
  toDate: initDatesFromURL().toDate,
  focusedEquipmentId: null,
  focusedObservationId: null,
  focusedDriverId: null,
  showParcelNames: localStorage.getItem(localStorageKeys.parcelsName) === 'true',
  showMarkerClusters: localStorage.getItem(localStorageKeys.showMarkerClusters) === 'true',
  hideEquipmentTooltip: false,
  hideDriverTooltip: false,
};

function reducer(state, action) {
  const { id, fromDate, toDate, type, hideTooltip } = action;
  switch (type) {
    case Actions.DATE_META_MODIFIED:
      return {
        ...state,
        fromDate,
        toDate,
      };
    case Actions.TOGGLE_PARCEL_NAMES:
      localStorage.setItem(localStorageKeys.parcelsName, `${!state.showParcelNames}`);
      return {
        ...state,
        showParcelNames: !state.showParcelNames,
      };
    case Actions.TOGGLE_MARKER_CLUSTERS:
      localStorage.setItem(localStorageKeys.showMarkerClusters, `${!state.showMarkerClusters}`);
      return {
        ...state,
        showMarkerClusters: !state.showMarkerClusters,
      };
    case Actions.BLUR_EQUIPMENT:
      return {
        ...state,
        focusedEquipmentId: null,
      };
    case Actions.FOCUS_EQUIPMENT:
      return {
        ...state,
        focusedEquipmentId: id,
      };
    case Actions.FOCUS_DRIVER:
      return {
        ...state,
        focusedDriverId: id,
      };
    case Actions.BLUR_DRIVER:
      return {
        ...state,
        focusedDriverId: null,
      };
    case Actions.FOCUS_WORKSITE:
      return {
        ...state,
        focusedWorksiteId: id,
      };
    case Actions.BLUR_WORKSITE:
      return {
        ...state,
        focusedWorksiteId: null,
      };

    case Actions.HIDE_EQUIPMENT_TOOLTIP:
      return {
        ...state,
        hideEquipmentTooltip: hideTooltip,
      };
    case Actions.HIDE_DRIVER_TOOLTIP:
      return {
        ...state,
        hideDriverTooltip: hideTooltip,
      };
    default:
      throw new Error();
  }
}

export function Provider({ children }) {
  const focusedObservationId = useSelector((state) => state.observations.general.focusedObservationId);
  const user = useSelector((state) => state.user);
  const isThereDemoClusters = useSelector(demoClustersSelector);
  const isViticulture = useSelector(isViticultureContext);
  const isDeneigement = useSelector(isDeneigementContext);

  const [demoModalOpened, openDemoModal, closeDemoModal] = UIHooks.useOpenCloseState(false);
  const [uiState, uiDispatch] = useReducer(reducer, initialState);

  const {
    focusedEquipmentId,
    fromDate,
    showParcelNames,
    showMarkerClusters,
    toDate,
    focusedDriverId,
    focusedWorksiteId,
    hideDriverTooltip,
    hideEquipmentTooltip,
  } = uiState;
  const focusEquipment = useCallback((id) => uiDispatch({ type: Actions.FOCUS_EQUIPMENT, id }), []);
  const blurEquipment = useCallback(() => uiDispatch({ type: Actions.BLUR_EQUIPMENT }), []);
  const focusDriver = useCallback((id) => uiDispatch({ type: Actions.FOCUS_DRIVER, id }), []);
  const blurDriver = useCallback(() => uiDispatch({ type: Actions.BLUR_DRIVER }), []);
  const focusWorksite = useCallback((id) => uiDispatch({ type: Actions.FOCUS_WORKSITE, id }), []);
  const blurWorksite = useCallback(() => uiDispatch({ type: Actions.BLUR_WORKSITE }), []);
  const toggleParcelNames = useCallback(() => uiDispatch({ type: Actions.TOGGLE_PARCEL_NAMES }), []);
  const toggleMarkerClusters = useCallback(() => uiDispatch({ type: Actions.TOGGLE_MARKER_CLUSTERS }), []);
  const setHideEquipmentTooltip = useCallback(
    (hideTooltip) => uiDispatch({ type: Actions.HIDE_EQUIPMENT_TOOLTIP, hideTooltip }),
    [],
  );
  const setHideDriverTooltip = useCallback(
    (hideTooltip) => uiDispatch({ type: Actions.HIDE_DRIVER_TOOLTIP, hideTooltip }),
    [],
  );
  const modifyDates = useCallback((fromDate, toDate) => {
    handleDateURLParams(fromDate, toDate);
    return uiDispatch({
      type: Actions.DATE_META_MODIFIED,
      fromDate,
      toDate,
    });
  }, []);

  const units = useMemo(() => {
    return {
      area: user.unit_area,
      distance: user.unit_distance,
      time: user.unit_time,
    };
  }, [user]);

  const setTrackStyle = useTracksHighlightStyle();

  const trackOverStyle = useMemo(
    () => ({
      strokeColor: colors('orange'),
      color: colors('orange'),
      pane: 'markerPane',
      weight: 3,
    }),
    [],
  );

  return (
    <Context.Provider
      value={{
        blurDriver,
        blurEquipment,
        blurWorksite,
        closeDemoModal,
        demoAccount: isThereDemoClusters,
        demoModalOpened,
        deneigementAccount: isDeneigement,
        focusDriver,
        focusEquipment,
        focusWorksite,
        focusedEquipmentId,
        focusedObservationId,
        focusedDriverId,
        focusedWorksiteId,
        fromDate,
        hideEquipmentTooltip,
        hideDriverTooltip,
        setHideEquipmentTooltip,
        setHideDriverTooltip,
        modifyDates,
        openDemoModal,
        setTrackStyle,
        showParcelNames,
        showMarkerClusters,
        toDate,
        toggleParcelNames,
        toggleMarkerClusters,
        trackOverStyle,
        uiDispatch,
        uiState,
        units,
        viticultureAccount: isViticulture,
      }}
    >
      {children}
    </Context.Provider>
  );
}
