import { MouseEventHandler, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from '@karnott/buttons';
import { colors } from '@karnott/colors';
import { DEVICES_CONSTANTS } from '@karnott/constants';
import {
  BatteryIcon,
  BeaconIcon,
  ClusterIcon,
  ImplementIcon,
  KIcon,
  KarnottIcon,
  TimeIcon,
  WarningIcon,
} from '@karnott/icons';
import { Tag } from '@karnott/tags';
import { fontFamily, pixelSize, pixelSpacing } from '@karnott/theme';

const ImplementPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-family: ${fontFamily()};
`;

const ImplementPopupRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Content = styled.div`
  margin-top: 5px;
  margin-left: ${pixelSpacing('small')};
`;

const ImplementPopupRowHeaderLogo = styled.div`
  position: relative;
  display: flex;
`;

const ImplementPopupRowHeaderLabel = styled.div`
  margin-left: ${pixelSpacing('small')};
`;

const ImplementPopupRowHeaderLabelName = styled.div`
  font-weight: 700;
  font-size: ${pixelSize('large')};
`;

const ImplementPopupRowIcon = styled.div`
  display: flex;
  width: 20px;
  justify-content: center;
`;

const ImplementPopupRowLabel = styled.span`
  display: flex;
  margin: 0px ${pixelSpacing('xSmall')};
  text-overflow: hidden;
  font-size: 13px;
`;

const ImplementPopupRowAction = styled.div`
  display: flex;
  gap: 5px;
  height: 24px;
  flex-direction: row;
  justify-content: right;
  margin-top: 10px;
`;

type Props = {
  /** Equipment icon */
  Icon: KIcon;
  /** Equipment label */
  implementLabel: string;
  /** Whether the equipment is active */
  isActive?: boolean;
  /** Equipment type */
  implementType: string;
  /** The name of the equipment’s cluster */
  clusterName: string;
  /** Date of the last update */
  lastDate: string;
  /** Device type linked to the equipment */
  deviceType?: 'karnott' | 'beacon';
  /** Device label */
  deviceLabel: string;
  /** Device battery level */
  deviceBattery?: number;
  /** Whether the device is charging */
  batteryCharging?: boolean;
  /** Whether the device has a warning */
  warning?: boolean;
  /** Function to call when the chip is clicked */
  chipAction: MouseEventHandler<HTMLButtonElement>;
  /** I18n labels */
  labels: {
    active: string;
    inactive: string;
    last_position: string;
    see: string;
    diagnose: string;
  };
  /** Function to call when the diagnose button is clicked */
  diagnoseAction: MouseEventHandler<HTMLButtonElement>;
};

export function ImplementPopup({
  Icon,
  implementLabel,
  isActive = false,
  implementType,
  clusterName,
  lastDate,
  deviceType,
  deviceLabel,
  deviceBattery,
  batteryCharging = false,
  warning = false,
  chipAction,
  labels,
  diagnoseAction,
}: Props) {
  const batteryColor = useMemo(() => {
    if (!deviceBattery) {
      return;
    }
    if (deviceBattery > DEVICES_CONSTANTS.KARNOTT.ORANGE_BATTERY_LEVEL) return colors('green');
    if (deviceBattery > DEVICES_CONSTANTS.KARNOTT.RED_BATTERY_LEVEL) return colors('orange');
    return colors('red');
  }, [deviceBattery]);

  const splitClusterName = useMemo(() => {
    let splitContent = clusterName;
    if ((clusterName?.length || 0) > 20) {
      splitContent = clusterName.slice(0, 20) + '...';
    }
    return splitContent;
  }, [clusterName]);

  return (
    <ImplementPopupWrapper>
      <ImplementPopupRow>
        <ImplementPopupRowHeaderLogo>
          <Icon
            circled
            backgroundColor={colors('black', 'dark')}
            color={colors('white')}
            size={40}
            healthIssue={warning}
          />
        </ImplementPopupRowHeaderLogo>
        <ImplementPopupRowHeaderLabel>
          <ImplementPopupRowHeaderLabelName>{implementLabel}</ImplementPopupRowHeaderLabelName>
          <Tag
            active={isActive}
            label={isActive ? labels.active.toLocaleUpperCase() : labels.inactive.toLocaleUpperCase()}
          />
        </ImplementPopupRowHeaderLabel>
      </ImplementPopupRow>

      <Content>
        <ImplementPopupRow>
          <ImplementPopupRowIcon>
            <ImplementIcon size={16} />
          </ImplementPopupRowIcon>
          <ImplementPopupRowLabel>{implementType}</ImplementPopupRowLabel>
          <ImplementPopupRowIcon>
            <ClusterIcon size={12} />
          </ImplementPopupRowIcon>
          <ImplementPopupRowLabel>{splitClusterName}</ImplementPopupRowLabel>
        </ImplementPopupRow>

        <ImplementPopupRow>
          <ImplementPopupRowIcon>
            <TimeIcon size={12} />
          </ImplementPopupRowIcon>
          <ImplementPopupRowLabel>{`${labels.last_position} : ${lastDate}`}</ImplementPopupRowLabel>
        </ImplementPopupRow>

        {deviceType && (
          <ImplementPopupRow>
            <ImplementPopupRowIcon>
              {deviceType === 'karnott' ? (
                <KarnottIcon size={16} />
              ) : deviceType === 'beacon' ? (
                <BeaconIcon size={12} />
              ) : null}
            </ImplementPopupRowIcon>
            <ImplementPopupRowLabel>{deviceLabel}</ImplementPopupRowLabel>

            {deviceType === 'karnott' ? (
              <>
                <ImplementPopupRowIcon>
                  <BatteryIcon
                    batteryColor={batteryColor}
                    batteryLevel={deviceBattery}
                    charging={batteryCharging}
                    size={14}
                    color={colors('black', 'dark')}
                  />
                </ImplementPopupRowIcon>

                <ImplementPopupRowLabel>{`${deviceBattery} %`}</ImplementPopupRowLabel>
              </>
            ) : null}
          </ImplementPopupRow>
        )}
      </Content>
      <ImplementPopupRowAction>
        <Button primary thin title={labels.see} onClick={chipAction} rounded />
        {warning && <Button danger thin rounded Icon={WarningIcon} title={labels.diagnose} onClick={diagnoseAction} />}
      </ImplementPopupRowAction>
    </ImplementPopupWrapper>
  );
}
