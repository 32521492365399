import { createSelector } from '@reduxjs/toolkit';
import { createAppSlice } from '.';
import { createTag as createApiTag, deleteTag as deleteApiTag, getTags, updateTag as updateApiTag } from '../api/api';
import { sortAlphaBeta } from '../utils';
import { ItemWithPermissions } from '../utils/permissions';
import { randomHexColor } from '../utils/tags';
import { ClusterId } from './clusters';
import { alerting, success } from './snacks';

type TagId = string;

type Tag = ItemWithPermissions & {
  id: TagId;
  label: string;
  color: string;
  cluster_id: ClusterId;
  from_application: string;
  beacon_count?: number;
  cluster_count?: number;
  device_count?: number;
  equipment_count?: number;
  parcel_count?: number;
  user_count?: number;
};

type State = {
  tagsByClusterId: Record<ClusterId, Tag[]>;
};

const initialState: State = {
  tagsByClusterId: {},
};

const tagReceived = (state: State, { payload }: { payload: Tag }) => {
  state.tagsByClusterId[payload.cluster_id] = (state.tagsByClusterId[payload.cluster_id] || []).filter(
    (t) => t.id !== payload.id,
  );
  state.tagsByClusterId[payload.cluster_id]!.push(payload);
};

export const tagsSlice = createAppSlice({
  name: 'tags',
  initialState,
  reducers: (create) => ({
    fetchTags: create.asyncThunk(
      async () => {
        const tag = (await alerting(getTags)) as Tag[];
        return tag;
      },
      {
        fulfilled: (state, { payload }) => {
          state.tagsByClusterId = {};
          payload.forEach((tag) => {
            state.tagsByClusterId[tag.cluster_id] ??= [];
            state.tagsByClusterId[tag.cluster_id]!.push(tag);
          });
        },
      },
    ),
    createTag: create.asyncThunk(
      async (newTag: { label: string; clusterId: ClusterId; color?: string }) => {
        const tag = (await alerting(() =>
          createApiTag(newTag.label, newTag.color || randomHexColor(), newTag.clusterId),
        )) as Tag;
        success('Admin.create_tag_snack');
        return tag;
      },
      { fulfilled: tagReceived },
    ),
    updateTag: create.asyncThunk(
      async (payload: { id: TagId; tag: Partial<Tag> }) => {
        const tag = (await alerting(() => updateApiTag(payload.id, payload.tag))) as Tag;
        success('Admin.edit_tag_snack');
        return tag;
      },
      { fulfilled: tagReceived },
    ),
    deleteTag: create.asyncThunk(
      async (id: TagId) => {
        await alerting(() => deleteApiTag(id));
        success('Admin.delete_tag_snack');
        return id;
      },
      {
        fulfilled: (state, { payload: id }) => {
          for (const clusterId in state.tagsByClusterId) {
            state.tagsByClusterId[clusterId] = state.tagsByClusterId[clusterId]!.filter((t) => t.id !== id);
          }
        },
      },
    ),
  }),
  selectors: {
    selectTagsByClusterId: (state) => state.tagsByClusterId,
  },
});

export const { fetchTags, createTag, updateTag, deleteTag } = tagsSlice.actions;
export const { selectTagsByClusterId } = tagsSlice.selectors;

export const selectSortedTags = createSelector([selectTagsByClusterId], (tagsByClusterId) =>
  Object.values(tagsByClusterId)
    .flat()
    .sort((a, b) => sortAlphaBeta(a.label, b.label)),
);

export default tagsSlice.reducer;
