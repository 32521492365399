export const buildTracksFromWorksites = (worksites) => {
  const worksitesWithTracks = (worksites || []).filter((w) => !!w.tracks);
  if (!worksitesWithTracks?.length) return null;
  const tracks = {
    type: 'FeatureCollection',
  };
  let features = [];
  worksitesWithTracks.forEach((worksite) => {
    let activeSession = {};
    activeSession.geometry = worksite.track;
    activeSession.properties = {
      device_session_id: worksite.device_session_id,
      fromDate: worksite.from_date,
      equipment_model_type: worksite?.equipment_model,
    };
    activeSession.type = 'Feature';
    features.push(activeSession);
  });
  tracks.features = features;
  return tracks;
};

export function getParcelsFromWorksite(worksite) {
  return worksite.worked_parcel_with_tasks.map((p) => ({
    id: p.parcel_id,
    name: p.parcel_name,
    area: p.parcel_area,
    computedArea: p.worked_area || 0,
    category: p.parcel_category,
  }));
}

export function getEquipmentFromWorksite(worksite) {
  return worksite?.equipment_instances.find(
    (e) => (e.equipment_model || e.equipmentModel?.type) === worksite.equipment_model,
  );
}

export function getWorksiteKey(worksite) {
  const equipment = getEquipmentFromWorksite(worksite);
  if (!equipment) return 'error';
  return `${equipment.id}-${worksite.cluster_id}-${worksite.device_session_id}`;
}

/**
 * @param   {Worksite}           worksite
 *
 * @returns {number | undefined}          `undefined` if this worksite has two or zero drivers, and the driver’s id if
 *   it’s the only one
 */
export function getUniqueDriverIdFromWorksite(worksite) {
  const userIds = new Set(
    worksite.sessions
      .map((s) => s.parcel_sessions)
      .flat()
      .map((p) => p?.user_id)
      .filter(Boolean),
  );
  return userIds.size === 1 ? userIds.values().next().value : undefined;
}
