import { useCallback, useState } from 'react';

export function useLayerFilters({ initVal = {} }) {
  const [filters, setFilters] = useState(initVal);
  const toggleFilter = useCallback(
    (key) => {
      localStorage.setItem(`${key}`, `${!filters[key]}`);
      setFilters({
        ...filters,
        [key]: !filters[key],
      });
    },
    [setFilters, filters],
  );
  return [filters, toggleFilter];
}
