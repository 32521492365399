import { colors } from '@karnott/colors';
import CircledIcon from '../utils/circledIcon';

export const TrashIcon = ({ color = '#000', size = 30, backgroundColor = colors('grey'), circled = false }) => (
  <CircledIcon {...{ backgroundColor, circled, size }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={circled ? '50%' : '100%'}
      height={circled ? '50%' : '100%'}
      viewBox="0 0 22.5 22"
    >
      <g transform="translate(1 1)">
        <path
          d="M329.566,71.09l.1-1.857A1.73,1.73,0,0,1,331.4,67.5h4.4a1.73,1.73,0,0,1,1.726,1.733l.105,1.857"
          transform="translate(-323.347 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          d="M337.721,87.5h-8.248a3.242,3.242,0,0,1-3.237-3.247L324.66,71.09h17.874l-1.576,13.163A3.243,3.243,0,0,1,337.721,87.5Z"
          transform="translate(-323.347 -67.5)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x2="20.5"
          transform="translate(0 3.59)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          x1="5.701"
          y1="5.72"
          transform="translate(7.399 8.935)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          y1="5.72"
          x2="5.701"
          transform="translate(7.399 8.935)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  </CircledIcon>
);
