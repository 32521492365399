import { createContext, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { setTitle } from '../actions/ui';
import SnackBar from '../components/SnackBar';
import { criticalSnackAdded, snackAdded, snacksCleared } from '../slices/snacks';

export const SnacksContext = createContext();

function SnacksProvider({
  children,
  dispatchSendSnack,
  dispatchClearSnacks,
  dispatchSetTitle,
  dispatchSendImportantSnack,
}) {
  const [lastSentAt, setLastSentAt] = useState(null);
  const [throttleLimit] = useState(500);

  const shouldThrottle = useCallback(() => {
    return lastSentAt && new Date().getTime() - lastSentAt < throttleLimit;
  }, [lastSentAt, throttleLimit]);

  const sendPageview = useCallback(() => {
    if (shouldThrottle()) {
      return;
    }

    setLastSentAt(new Date().getTime());
  }, [shouldThrottle, setLastSentAt]);

  const sendEvent = useCallback(() => {
    if (shouldThrottle()) {
      return;
    }

    setLastSentAt(new Date().getTime());
  }, [shouldThrottle, setLastSentAt]);

  const sendSnack = useCallback(
    (snack) => {
      const duration = snack.duration === false ? false : snack.duration || 5000;
      const type = snack.type || 'info';

      dispatchSendSnack({ ...snack, type, duration });
    },
    [dispatchSendSnack],
  );

  const sendImportantSnack = useCallback(
    (snack) => {
      dispatchSendImportantSnack({ snack });
    },
    [dispatchSendImportantSnack],
  );

  const clearSnacks = useCallback(() => dispatchClearSnacks(), [dispatchClearSnacks]);

  const setTitle = useCallback((title) => dispatchSetTitle(title), [dispatchSetTitle]);

  return (
    <>
      <SnacksContext.Provider
        value={{
          sendSnack,
          clearSnacks,
          sendImportantSnack,
          sendPageview,
          sendEvent,
          setTitle,
        }}
      >
        {children}
      </SnacksContext.Provider>
      <SnackBar />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSendSnack: (snack) => dispatch(snackAdded(snack)),
  dispatchSendImportantSnack: (snack) => dispatch(criticalSnackAdded(snack)),
  dispatchClearSnacks: () => dispatch(snacksCleared()),
  dispatchSetTitle: (title) => dispatch(setTitle(title)),
});

const ConnectedSnacksProvider = connect(null, mapDispatchToProps)(SnacksProvider);
export default ConnectedSnacksProvider;
